<template>
  <div>
    <ChecHeader header-tag="h3" :title="$tc('product.variants.groups')">
      <ChecButton
        variant="round"
        color="primary"
        icon="plus"
        @click="addVariantGroup"
      >
        {{ $t('general.add') }}
      </ChecButton>
    </ChecHeader>
    <ChecTable class="variant-groups" snug>
      <thead>
        <tr>
          <th class="variant-groups__row-header-name">
            {{ $tc('general.name', 1) }}
          </th>
          <th class="variant-groups__row-header-options">
            {{ $tc('product.variants.options', 2) }}
          </th>
          <th class="variant-groups__row-header-actions">
            {{ $t('general.actions') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="variantGroups.length === 0">
          <td colspan="5" class="variant-groups--empty">
            {{ $t('general.empty') }}
          </td>
        </tr>
        <VariantGroupRow
          v-for="(group, index) in variantGroups"
          :key="group.id || index"
          :deleted="group.deleted"
          :focus-on-mount="variantGroups.length === 1 && !group.id"
          :group="group"
          :product="product"
          :errors="errors"
          :group-index="index"
          class="variant-groups__group"
          @change="(newVariantGroup) => updateVariantGroup(group.id || index, newVariantGroup)"
          @delete="handleRemoveVariantGroup(group.id || index)"
          @saved="$emit('saved')"
        />
      </tbody>
    </ChecTable>
  </div>
</template>

<script>
import {
  ChecButton,
  ChecHeader,
  ChecTable,
} from '@chec/ui-library';
import VariantGroupRow from './VariantGroupRow.vue';

export default {
  name: 'VariantGroups',
  components: {
    ChecButton,
    ChecHeader,
    ChecTable,
    VariantGroupRow,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    errors: Object,
  },
  computed: {
    variantGroups() {
      return this.product.variantGroups;
    },
  },
  methods: {
    // Add a new empty variant group row
    addVariantGroup() {
      this.emitChange([...this.variantGroups, {
        name: '',
        options: [],
      }]);
    },
    // Remove a variant group row
    handleRemoveVariantGroup(id) {
      const groups = [...this.variantGroups];
      const index = this.findVariantGroupIndex(id);

      // If we were given an actual ID - then we just mark is as deleted so that we can display it
      // still in the UI
      const newGroup = (typeof id === 'string' && id.startsWith('v'))
        ? [{
          ...groups[index],
          deleted: true,
        }]
        : [];

      groups.splice(index, 1, ...newGroup);

      this.emitChange(groups);
    },
    findVariantGroupIndex(id) {
      return typeof id === 'string' && id.startsWith('v')
        ? this.variantGroups.findIndex((candidate) => candidate.id === id)
        : id;
    },
    updateVariantGroup(id, variantGroup) {
      const variantGroups = [...this.variantGroups];

      variantGroups.splice(this.findVariantGroupIndex(id), 1, variantGroup);
      this.emitChange(variantGroups);
    },
    emitChange(variantGroups) {
      this.$emit('change-variant-groups', variantGroups);
    },
  },
};
</script>

<style lang="scss">
.variant-groups {
  @apply mt-4 mb-6;

  // TODO remove with UI lib fix
  table {
    @apply w-full;
  }

  &--empty {
    @apply text-gray-400;
  }

  &__row-header-name,
  &__row-header-options {
    @apply w-16;
  }

  &__row-header-actions {
    @apply w-px text-right;
  }

  // Override base table styling so the title aligns with the pills below it
  .variant-table__row-header-options {
    @apply px-3;
  }
}
</style>
