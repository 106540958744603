<template>
  <ChecCard
    tailwind="p-4"
    inner-class="extra-fields"
  >
    <ChecHeader
      variant="card"
      :title="$t('extraFields.extraFields')"
    >
      <ChecButton
        color="primary"
        variant="round"
        icon="plus"
        @click="handleAddField"
      >
        {{ $t('general.add') }}
      </ChecButton>
    </ChecHeader>
    <p class="extra-fields__subtitle">
      {{ $t('extraFields.description') }}
    </p>
    <InnerBlock
      v-if="extraFields.length"
      class="extra-fields__block-extra"
    >
      <Row
        v-for="(row, index) in extraFields"
        :key="row.id || index"
        :row="row"
        :index="index"
        :errors="errors[index] || {}"
        @delete="handleDeleteField(index)"
        @change="(row) => handleUpdateField(index, row)"
      />
    </InnerBlock>
    <InnerBlock
      :title="$t('general.options')"
      class="extra-fields__block-options"
    >
      <hr class="extra-fields__divider">
      <ChecSwitch
        :toggled="collect.shipping_address"
        name="collect_shipping"
        class="extra-fields__option"
        @input="value => handleChangeOption('shipping_address', value)"
      >
        {{ $t('extraFields.collectsShipping') }}
      </ChecSwitch>
      <ChecSwitch
        :toggled="collect.billing_address"
        name="collect_billing"
        class="extra-fields__option"
        @input="value => handleChangeOption('billing_address', value)"
      >
        {{ $t('extraFields.collectsBilling') }}
      </ChecSwitch>
      <ChecSwitch
        :toggled="collect.fullname"
        name="collect_fullname"
        class="extra-fields__option"
        @input="value => handleChangeOption('fullname', value)"
      >
        {{ $t('extraFields.collectsFullName') }}
      </ChecSwitch>
    </InnerBlock>
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecCard,
  ChecHeader,
  InnerBlock,
  ChecSwitch,
} from '@chec/ui-library';
import Row from './ExtraFields/Row.vue';

export default {
  name: 'ExtraFields',
  components: {
    ChecCard,
    ChecButton,
    ChecHeader,
    InnerBlock,
    ChecSwitch,
    Row,
  },
  props: {
    extraFields: {
      type: Array,
    },
    collect: {
      type: Object,
    },
    errors: {
      type: Array,
      default: () => ([]),
    },
  },
  methods: {
    /**
     * Click handler for "add" button, adds extra fields row
     */
    handleAddField() {
      this.$emit('change-extra-fields', [
        ...this.extraFields,
        {
          name: '',
          required: false,
        },
      ]);
    },
    /**
     * Click handler for "delete" button, deletes extra fields row
     */
    handleDeleteField(index) {
      const fields = [...this.extraFields];

      fields.splice(index, 1);

      this.$emit('change-extra-fields', fields);
    },
    /**
     * Change handler for text input for extra fields
     */
    handleUpdateField(index, field) {
      const fields = [...this.extraFields];

      fields.splice(index, 1, field);

      this.$emit('change-extra-fields', fields);
    },
    /**
     * Change handler for the "options" switches
     */
    handleChangeOption(option, value) {
      this.$emit('change-option', option, value);
    },
  },
};
</script>

<style lang="scss">
.extra-fields {
  &__subtitle {
    @apply my-4 text-sm;
  }

  &__block-extra {
    @apply w-full;

    > div {
      @apply flex-grow;
    }
  }

  &__block-options {
    > div {
      @apply flex-grow;
    }
  }

  &__options {
    @apply block;
  }

  &__divider {
    @apply border-gray-300 my-3;
  }

  &__row {
    @apply pb-3 w-full;
  }

  &__row-input {
    @apply w-full;
  }

  &__row-btn {
    @apply ml-2;
    align-self: center;
  }

  &__row-wrapper {
    @apply flex;
  }

  &__row-input-container {
    @apply w-full;
  }

  &__option {
    @apply py-3;
  }
}
</style>
