var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DashboardTable',{staticClass:"products-table",attrs:{"align-right-after":1,"columns":[
    { title: _vm.$t('product.product') },
    { title: _vm.$t('general.remaining'), class: 'products-table__quantity-header' },
    { title: _vm.$t('order.orders'), class: 'products-table__orders-header' },
    { title: _vm.$t('general.sales'), class: 'products-table__sales-header' },
    { title: _vm.$t('general.options'), class: 'products-table__options-header' } ],"show-search":"","state-key":"products"}},_vm._l((_vm.products),function(ref){
  var id = ref.id;
  var permalink = ref.permalink;
  var image = ref.image;
  var name = ref.name;
  var inventory = ref.inventory;
  var statistics = ref.statistics;
return _c('TableRow',{key:id,attrs:{"id":id,"route-name":"products.edit"}},[_c('td',[_c('div',{staticClass:"products-table__image-title"},[_c('div',{staticClass:"products-table__image-container"},[(image)?_c('img',{attrs:{"src":image.url,"alt":image.description || name}}):_c('div',{staticClass:"products-table__no-image"},[_vm._v(" "+_vm._s(_vm.$t('product.noImage'))+" ")])]),_c('span',[_c('router-link',{attrs:{"to":{
              name: 'products.edit',
              params: { id: id },
            }}},[_vm._v(" "+_vm._s(name)+" ")])],1)])]),_c('td',[_c('ChecDataPill',[_vm._v(" "+_vm._s(inventory.managed ? inventory.available.toLocaleString() : '∞')+" ")])],1),_c('td',[_vm._v(" "+_vm._s(statistics && statistics.orders.toLocaleString())+" ")]),_c('td',{staticClass:"products-table__price"},[_vm._v(" "+_vm._s(statistics && _vm.money(statistics.sales).full())+" ")]),_c('td',[_c('ChecOptionsMenu',{staticClass:"products-table__options"},[_c('ChecOption',{on:{"option-selected":function($event){return _vm.handleEdit(id)}}},[_vm._v(" "+_vm._s(_vm.$t('general.edit'))+" ")]),(_vm.hasEnabledHostedCheckout)?_c('ChecOption',{on:{"option-selected":function($event){return _vm.handleShare(permalink)}}},[_vm._v(" "+_vm._s(_vm.$t('general.shareEmbed'))+" ")]):_vm._e(),_c('ChecOption',{attrs:{"destructive":""},on:{"option-selected":function () { return _vm.handleDelete(id); }}},[_vm._v(" "+_vm._s(_vm.$t('general.delete'))+" ")])],1)],1)])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }