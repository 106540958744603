<template>
  <TableRow
    class="variant-row"
    :class="rowClasses"
    @click="editVariant(options)"
  >
    <td v-tooltip="invalidReason">
      <div class="variant-row__initial-cell">
        <img
          v-if="image"
          class="variant-table__thumb"
          :src="image.url"
          :alt="label"
        >
        <div v-else class="variant-table__no-image-thumbnail">
          {{ $t('product.noImage') }}
        </div>
      </div>
    </td>
    <td>
      <div class="variant-table__options">
        <OptionPills
          v-if="hasOptions"
          :options="options"
        />
        <span v-else>-</span>
      </div>
    </td>
    <td>
      <TextField
        v-model="inventory"
        :variant="deleted ? 'disabled' : ''"
        placeholder="∞"
        type="number"
        @click.stop
      />
    </td>
    <td>
      <VariantPriceTooltip
        :currency="merchant.currency"
        :options="options"
        :product-price="productPrice"
        :set-price="isNaN(parseFloat(price)) ? null : parseFloat(price)"
      >
        <TextField
          v-model="price"
          :variant="deleted ? 'disabled' : ''"
          :currency-symbol="merchant.currency.symbol"
          currency
          :placeholder="defaultPrice"
          @click.stop
        >
          <ChecIcon icon="info-square" class="variant-row__price-help" size="sm" />
        </TextField>
      </VariantPriceTooltip>
    </td>
    <td>
      <TextField
        v-model="sku"
        :variant="deleted ? 'disabled' : ''"
        :placeholder="$t('product.sku')"
        @click.stop
      />
    </td>
    <td v-if="deleted">
      <ChecButton
        variant="round"
        color="primary"
        outline
        @click="emitUndoDelete"
      >
        {{ $t('general.cancel') }}
      </ChecButton>
    </td>
    <td v-else>
      <ChecOptionsMenu class="variant-table__actions-button">
        <ChecOption
          @option-selected="() => editVariant(options)"
        >
          {{ $t('general.edit') }}
        </ChecOption>
        <ChecOption destructive @option-selected="emitDelete">
          {{ $t('general.delete') }}
        </ChecOption>
      </ChecOptionsMenu>
    </td>
  </TableRow>
</template>

<script>
import { mapState } from 'vuex';
import {
  ChecButton,
  ChecIcon,
  ChecOption,
  ChecOptionsMenu,
  TextField,
} from '@chec/ui-library';
import TableRow from '@/components/DashboardTable/TableRow.vue';
import OptionPills from './OptionPills.vue';
import VariantPriceTooltip from './VariantPriceTooltip.vue';

export default {
  name: 'VariantRow',
  components: {
    TableRow,
    ChecButton,
    ChecIcon,
    ChecOption,
    ChecOptionsMenu,
    OptionPills,
    TextField,
    VariantPriceTooltip,
  },
  model: {
    prop: 'variant',
    event: 'change',
  },
  props: {
    variant: Object,
    productPrice: [String, null],
    deleted: Boolean,
  },
  computed: {
    ...mapState('merchant', ['merchant']),
    /**
     * Calculates a default price for this variant, if there is no specific price set
     *
     * @returns {String}
     */
    defaultPrice() {
      return (
        parseFloat(this.productPrice)
        + this.options.reduce((acc, { price }) => acc + (price?.raw || 0), 0)
      ).toFixed(2);
    },
    /**
     * Finds an image on the variant, filtering out any asset attached that isn't an image
     *
     * @returns {null|Object}
     */
    image() {
      if (!Array.isArray(this.variant.assets)) {
        return null;
      }

      return this.variant.assets.find((candidate) => candidate.is_image) || null;
    },
    invalidReason() {
      const { is_valid: isValid, invalid_reason_code: invalidReason } = this.variant;

      if (isValid) {
        return null;
      }

      return this.$t(`product.variants.invalidReason.${invalidReason}`);
    },
    /**
     * Creates a label for the variants from the names of the options
     *
     * @returns {String}
     */
    label() {
      return this.options.map(({ name, group }) => `${group.name}: ${name}`).join(', ');
    },
    /**
     * Return the options
     *
     * @returns {Array}
     */
    options() {
      return this.variant.options;
    },
    /**
     * Checks if options are available
     *
     * @returns {Boolean}
     */
    hasOptions() {
      const options = this.variant.options.filter((option) => !!option.name);
      return !!options.length;
    },
    rowClasses() {
      return {
        'variant-row--deleted': this.deleted,
        'variant-row--invalid': !this.variant.is_valid,
      };
    },
    /**
     * Computed attributes that serve as v-models for the inputs in the row
     */
    sku: {
      get() {
        return this.variant.sku;
      },
      set(sku) {
        this.$emit('change', {
          ...this.variant,
          sku,
        });
      },
    },
    price: {
      get() {
        const { price } = this.variant;

        if (price && typeof price === 'object') {
          return price.raw;
        }
        return price;
      },
      set(price) {
        this.$emit('change', {
          ...this.variant,
          price,
        });
      },
    },
    inventory: {
      get() {
        return this.variant.inventory;
      },
      set(inventory) {
        this.$emit('change', {
          ...this.variant,
          inventory,
        });
      },
    },
  },
  methods: {
    emitDelete() {
      this.$emit('delete');
    },
    emitUndoDelete() {
      this.$emit('undo-delete');
    },
    editVariant(options) {
      this.$emit('edit-variant', { ...this.variant, selectedOptions: options });
    },
  },
};
</script>

<style lang="scss">
.variant-row {
  @apply h-full;

  &--deleted {
    > :not(:last-child) {
      @apply opacity-40;
    }
  }

  &__initial-cell {
    @apply pl-4 py-3 pr-1 h-full;
  }

  &--invalid .variant-row__initial-cell {
    @apply border-l-4 border-hazard-gray;

    padding-left: calc(1rem - 4px);
  }

  &__price-help {
    // TODO replace with @apply cursor-help when available
    cursor: help;
  }
}

// Messy override of table styling
.chec-table--snug .variant-row td:first-child {
  @apply p-0 h-full;
}
</style>
