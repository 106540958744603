<template>
  <div class="shipping-card__item-form">
    <ChecFormField>
      <TextField
        :value="rate.name"
        :label="$t('product.shippingCard.nameLabel')"
        name="name"
        minimized-label
        variant="disabled"
        @input="value => handleChange('name', value)"
      />
    </ChecFormField>
    <ChecFormField
      append-label=""
      :tooltip="$t('product.shippingCard.baseRateTooltip')"
    >
      <TextField
        :value="rate.rate.raw"
        :label="$t('product.shippingCard.baseRateLabel')"
        name="base_rate"
        minimized-label
        :currency-symbol="currencySymbol"
        currency
        variant="disabled"
        @input="value => handleChange('base_rate', value)"
      />
    </ChecFormField>
    <ChecIcon class="shipping-card__item-form-seperator" icon="plus" />
    <ChecFormField
      append-label=""
      :tooltip="$t('product.shippingCard.onOwnTooltip')"
    >
      <TextField
        :value="shippingOption.on_own.raw"
        :label="$t('product.shippingCard.onOwnLabel')"
        name="on_own"
        type="number"
        step="any"
        minimized-label
        :currency-symbol="currencySymbol"
        currency
        @input="value => handleChange('on_own', value)"
      />
      <span
        v-if="validationErrors[`fulfillment.physical[${rateIndex}].on_own.raw`]"
        class="input-wrapper__error"
      >
        {{ validationErrors[`fulfillment.physical[${rateIndex}].on_own.raw`] }}
      </span>
    </ChecFormField>
    <ChecFormField
      append-label=""
      :tooltip="$t('product.shippingCard.withOthersTooltip')"
    >
      <TextField
        :value="shippingOption.with_others.raw"
        :label="$t('product.shippingCard.withOthersLabel')"
        name="with_others"
        type="number"
        step="any"
        minimized-label
        :currency-symbol="currencySymbol"
        currency
        @input="value => handleChange('with_others', value)"
      />
      <span
        v-if="validationErrors[`fulfillment.physical[${rateIndex}].with_others.raw`]"
        class="input-wrapper__error"
      >
        {{ validationErrors[`fulfillment.physical[${rateIndex}].with_others.raw`] }}
      </span>
    </ChecFormField>
  </div>
</template>

<script>
import {
  TextField,
  ChecFormField,
  ChecIcon,
} from '@chec/ui-library';

export default {
  name: 'Row',
  components: {
    TextField,
    ChecIcon,
    ChecFormField,
  },
  props: {
    rate: Object,
    shippingOption: Object,
    validationErrors: Object,
    productRates: Array,
    currencySymbol: String,
  },
  computed: {
    rateIndex() {
      return this.productRates.findIndex((rate) => rate.rate.id === this.rate.id);
    },
  },
  methods: {
    handleChange(option, value) {
      this.$emit('change', {
        ...this.shippingOption,
        [option]: {
          raw: value,
        },
      });
    },
  },
};
</script>
