<template>
  <DashboardTable
    class="products-table"
    :align-right-after="1"
    :columns="[
      { title: $t('product.product') },
      { title: $t('general.remaining'), class: 'products-table__quantity-header' },
      { title: $t('order.orders'), class: 'products-table__orders-header' },
      { title: $t('general.sales'), class: 'products-table__sales-header' },
      { title: $t('general.options'), class: 'products-table__options-header' },
    ]"
    show-search
    state-key="products"
  >
    <TableRow
      v-for="({ id, permalink, image, name, inventory, statistics }) in products"
      :id="id"
      :key="id"
      route-name="products.edit"
    >
      <td>
        <div class="products-table__image-title">
          <div class="products-table__image-container">
            <img
              v-if="image"
              :src="image.url"
              :alt="image.description || name"
            >
            <div v-else class="products-table__no-image">
              {{ $t('product.noImage') }}
            </div>
          </div>
          <span>
            <router-link
              :to="{
                name: 'products.edit',
                params: { id },
              }"
            >
              {{ name }}
            </router-link>
          </span>
        </div>
      </td>
      <td>
        <ChecDataPill>
          {{ inventory.managed ? inventory.available.toLocaleString() : '&infin;' }}
        </ChecDataPill>
      </td>
      <td>
        {{ statistics && statistics.orders.toLocaleString() }}
      </td>
      <td class="products-table__price">
        {{ statistics && money(statistics.sales).full() }}
      </td>
      <td>
        <ChecOptionsMenu class="products-table__options">
          <ChecOption @option-selected="handleEdit(id)">
            {{ $t('general.edit') }}
          </ChecOption>
          <!-- todo implement support for "View orders for product -->
          <!-- <ChecOption>
            {{ $t('order.viewMultiple') }}
            </ChecOption> -->
          <ChecOption v-if="hasEnabledHostedCheckout" @option-selected="handleShare(permalink)">
            {{ $t('general.shareEmbed') }}
          </ChecOption>
          <ChecOption destructive @option-selected="() => handleDelete(id)">
            {{ $t('general.delete') }}
          </ChecOption>
        </ChecOptionsMenu>
      </td>
    </TableRow>
  </DashboardTable>
</template>

<script>
import { mapState } from 'vuex';
import money from '@/lib/helpers/money';
import {
  ChecDataPill,
  ChecOptionsMenu,
  ChecOption,
} from '@chec/ui-library';
import DashboardTable from '@/components/DashboardTable.vue';
import TableRow from '@/components/DashboardTable/TableRow.vue';

export default {
  name: 'ProductsTable',
  components: {
    ChecDataPill,
    ChecOptionsMenu,
    ChecOption,
    DashboardTable,
    TableRow,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('merchant', ['merchant']),
    hasEnabledHostedCheckout() {
      return this.merchant.has.enabled_hosted_checkouts || false;
    },
  },
  methods: {
    handleDelete(productId) {
      this.$emit('delete-product', productId);
    },
    handleEdit(productId) {
      this.$router.push({
        name: 'products.edit',
        params: { id: productId },
      });
    },
    handleShare(permalink) {
      this.$emit('share-product', permalink);
    },
    money,
  },
};
</script>

<style lang="scss">
.products-table {
  @apply table-fixed text-sm text-gray-500;

  &__loading-cell {
    @apply relative p-16;
  }

  &__quantity-header {
    @apply w-24;
  }

  &__orders-header,
  &__sales-header {
    @apply w-1/6;
  }

  &__options-header {
    @apply w-24;
  }

  &__image-title {
    @apply flex items-center;
  }

  &__price {
    @apply text-green-600;
  }

  &__image-container {
    @include aspect-ratio(1, 1);
    min-width: 3rem;

    .products-table__no-image {
      @apply flex justify-center items-center bg-gray-300 text-center opacity-50 text-xxs;
    }

    @apply rounded overflow-hidden mr-4;
  }

  &__options {
    @apply inline-block;
  }

  &--empty {
    @apply text-gray-400;
  }
}
</style>
