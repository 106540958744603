import i18n from '@/lang';
import {
  string,
  object,
  array,
  bool,
  // boolean,
  // mixed,
} from 'yup';

const positiveIntegerReg = /^\d+$|^$/; // Allows at least one number, or an empty string
const integerReg = /^-?\d+$|^$/; // Allows at least one number, or an empty string
const priceReg = /^\d+(\.\d+)?$|^$/;

export default object().shape({
  name: string().required(i18n.t('validation.enterName')),
  sku: string().nullable(),
  inventory: object({
    managed: bool(),
    available: string()
      .matches(positiveIntegerReg, i18n.t('validation.positiveNumber'))
      .nullable(),
  }),
  description: string().nullable(),
  price: string()
    .matches(priceReg, i18n.t('validation.validPrice'))
    // Only make price required when pay what you want is _off_
    .when('conditionals.is_pay_what_you_want', (pwyw, schema) => (
      pwyw ? schema : schema.required(i18n.t('validation.enterPrice'))
    )),
  fulfillment: object({
    physical: array().of(
      object({
        on_own: object({
          // @TODO: This works for now but we should probably revist this
          // and create a custom price method.
          raw: string().matches(priceReg, i18n.t('validation.validPrice')).nullable(),
        }),
        with_others: object({
          // @TODO: This works for now but we should probably revist this
          // and create a custom price method.
          raw: string().matches(priceReg, i18n.t('validation.validPrice')).nullable(),
        }),
      }),
    ),
  }),
  variantGroups: array().of(
    // @TODO: To remove from the product schema object
    // as variantGroups are in a separate schema
    object({
      name: string().required(i18n.t('validation.enterName')),
      options: array().of(
        object({
          name: string().required(i18n.t('validation.enterOption')),
        }),
      ),
    }),
  ),
  extra_fields: array().of(
    object({
      name: string().required(i18n.t('validation.enterName')),
    }),
  ),
  sort_order: string()
    .matches(integerReg, i18n.t('validation.number'))
    .nullable(),
  thank_you_url: string().url(i18n.t('validation.enterUrl')).nullable(),
  conditionals: object({
    is_pay_what_you_want: bool(),
  }),
});
