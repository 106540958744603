<template>
  <ChecSlideoutPanel
    :title="$t('product.variants.edit')"
    close-on-overlay-click
    @close="close"
  >
    <!-- Appears when there are no groups or variants set -->
    <EmptyMessage
      v-if="updatedGroups.length === 0"
      :title="$t('product.variants.noGroupsTitle')"
      big
    >
      <p>
        {{ $t('product.variants.noGroups') }}
      </p>
      <div class="manage-variants__hero-toolbar">
        <ChecButton
          v-if="product.id"
          color="primary"
          variant="round"
          icon="plus"
          @click="addVariantGroup"
        >
          {{ $t('product.variants.addGroup') }}
        </ChecButton>
        <ChecAlert
          v-else
          variant="info"
          disable-close
          show-icon
          inline
        >
          {{ $t('product.variants.requiresSave') }}
        </ChecAlert>
      </div>
    </EmptyMessage>
    <template v-else>
      <VariantGroups
        :product="{ ...product, variantGroups: updatedGroups }"
        :errors="validationErrors"
        @change-variant-groups="changeGroups"
        @saved="handleSavedEvent"
      />
      <VariantTable
        v-model="updatedVariants"
        :product="product"
        :dirty-groups="dirtyGroups"
        :show-invalidating-variants-warning="hasNewOrRemovedGroups"
        :deleted-variants="deletedVariants"
        @change-deleted-variants="updateDeletedVariants"
      />
    </template>
    <template #toolbar>
      <div class="manage-variants__toolbar space-x-4">
        <ChecButton text-only color="primary" @click="close">
          {{ $t('general.cancel' ) }}
        </ChecButton>
        <ChecButton
          v-if="!saving && !hasVariants"
          :disabled="!canSave"
          color="primary"
          @click="saveAndGenerateVariants"
        >
          {{ $t('product.variants.saveAndGenerate') }}
        </ChecButton>
        <ChecButton
          color="primary"
          :disabled="saving || !canSave"
          @click="save"
        >
          {{ saving ? $t('general.saving') : $t('general.saveChanges') }}
        </ChecButton>
      </div>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import {
  mapActions,
  mapState,
} from 'vuex';
import {
  ChecAlert,
  ChecButton,
  ChecSlideoutPanel,
} from '@chec/ui-library';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import { actions } from '@/modules/products/store/variants';
import generateVariants from '@/modules/products/lib/generateVariants';
import { actions as paginationActions } from '@/lib/pagination';
import addNotification from '@/mixins/addNotification';
import variantGroupsSchema from '../../../schemas/variantGroups';
import EmptyMessage from './EmptyMessage.vue';
import VariantTable from './VariantTable.vue';
import VariantGroups from './VariantGroups.vue';

export default {
  name: 'ManagePanel',
  components: {
    ChecAlert,
    ChecButton,
    ChecSlideoutPanel,
    EmptyMessage,
    VariantTable,
    VariantGroups,
  },
  mixins: [
    addNotification,
  ],
  props: {
    product: Object,
  },
  data() {
    return {
      saving: false,
      // Manage change state of groups in the panel to prevent variant cards from updating groups
      // before saving
      dirtyGroups: false,
      updatedVariants: [],
      deletedVariants: [],
      updatedGroups: [...this.product.variantGroups],
      validationErrors: {},
    };
  },
  computed: {
    ...mapState('products/variants', { variantCount: 'totalCount' }),
    canSave() {
      return this.updatedVariants.length > 0
        || this.deletedVariants.length > 0
        || this.updatedGroups.length > 0
        || this.dirtyGroups;
    },
    hasNewOrRemovedGroups() {
      return this.updatedGroups.length !== this.product.variantGroups.length
        || this.updatedGroups.some((candidate) => candidate.deleted);
    },
    hasVariants() {
      // This computed attribute is tri-state as variants may not have been loaded yet
      if (this.variantCount === null) {
        return null;
      }

      return this.variantCount > 0;
    },
  },
  methods: {
    ...mapActions('products/variants', {
      createVariants: actions.BULK_CREATE,
      updateVariants: actions.BULK_UPDATE,
      deleteVariants: actions.BULK_DELETE,
    }),
    ...mapActions('products', {
      saveProduct: paginationActions.UPDATE_ITEM,
    }),
    close() {
      this.$emit('close');
    },
    changeGroups(groups) {
      this.dirtyGroups = true;
      this.updatedGroups = groups;
    },
    handleSavedEvent() {
      this.$emit('saved');

      this.$nextTick(() => {
        this.updatedGroups = [...this.product.variantGroups];
      });
    },
    addVariantGroup() {
      this.dirtyGroups = true;
      this.updatedGroups = [
        ...this.updatedGroups,
        {
          name: '',
          options: [],
        },
      ];
    },
    async save(withVariants = false) {
      this.validationErrors = {};
      this.saving = true;

      // Prepare an array of promises as we'll do a bunch of operations asynchronously
      const promises = [];

      const validatedProductData = await this.validateProduct();

      // Assume validation will show to provide feedback
      if (!validatedProductData) {
        return;
      }

      promises.push(this.saveProduct(validatedProductData)
        .then(() => {
          // Set draft changes to false
          this.dirtyGroups = false;
          // Delegate actual API save to parent variant card
          this.$emit('change-variant-groups', validatedProductData.variant_groups);
          this.$emit('saved');

          // Refresh the groups from the product on the next tick, to give Vuex time to update and
          // re-render this component with the new product
          this.$nextTick(() => {
            this.updatedGroups = [...this.product.variantGroups];
          });
        }));

      const { deletedVariants, updatedVariants } = this;

      // Delete variants that have been removed
      promises.push(this.deleteVariants({ variantIds: deletedVariants }).catch(() => {
        // Restore the variants that were attempted to be deleted
        this.deletedVariants = deletedVariants;
      }));
      this.deletedVariants = [];

      const variants = updatedVariants
        // Exclude any variants that are being deleted from the update
        .filter((candidate) => !deletedVariants.includes(candidate.id))
        // Convert full option objects back to a group -> option map
        // TODO this has gotten really messy and really shouldn't be the place we do this
        .map((variant) => ({
          ...variant,
          options: variant.options.reduce((acc, option) => ({
            ...acc,
            [option.group.id]: option.id,
          }), {}),
        }));

      // Save any individual variants that have been updated
      promises.push(this.updateVariants({ variants }).catch(() => {
        // Restore any edits
        this.updatedVariants = updatedVariants;
      }));

      this.$nextTick(() => {
        this.updatedVariants = [];
      });

      if (promises.length === 0) {
        this.saving = false;
        return;
      }

      if (withVariants === true && variants.length === 0) {
        // Do the saving
        await Promise.all(promises);

        // Run a bulk add with all selected options on the next tick (so VueX can update option IDs)
        await this.$nextTick();

        const optionIds = this.product.variantGroups.reduce((acc, group) => ([
          ...acc,
          ...group.options.map(({ id }) => id),
        ]), []);
        const variantsToCreate = generateVariants(optionIds, this.product.variantGroups, []);

        // Remove the promises and replace with a create variants one to await in the next bit
        promises.splice(0, 3, this.createVariants({
          variants: variantsToCreate.map((options) => ({
            options: options.map(({ id }) => id),
          })),
        }));
      }

      Promise.all(promises)
        .then(() => {
          this.addNotification(this.$t('product.variants.bulkSuccess'));
        })
        .catch(() => {
          this.addNotification(this.$t('product.variants.bulkFailure'), 'error');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    async saveAndGenerateVariants() {
      return this.save(true);
    },
    async validateProduct() {
      // Remove groups that are marked as "deleted"
      const groups = this.updatedGroups.filter(({ deleted }) => !deleted);

      // Product data to save variant groups
      const productData = {
        id: this.product.id,
        item: {
          id: this.product.id,
          variantGroups: groups,
        },
        merge: false,
        optimistic: false,
      };

      try {
        // Validate variant groups data against the schema
        return await validateSchemaRequest(
          variantGroupsSchema,
          productData,
          { abortEarly: false },
        );
        // Once validated, save the whole product with only
        // the neccessary variant groups changes
      } catch (errors) {
        this.validationErrors = errors.errors;
      }

      return false;
    },
    updateDeletedVariants(variantIds) {
      this.deletedVariants = variantIds;
    },
  },
};
</script>

<style lang="scss">
.manage-variants {
  &__toolbar,
  &__hero-toolbar {
    @apply w-full flex justify-end items-center;
  }

  &__hero-toolbar {
    @apply mt-8;
  }
}
</style>
