<template>
  <table class="variant-group-table">
    <thead>
      <tr>
        <th>{{ $tc('product.variants.groups', 1) }}</th>
        <th>{{ $tc('product.variants.options', 2) }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="{ id, name, options } in variantGroups"
        :key="id"
      >
        <td>{{ name }}</td>
        <td class="space-x-2">
          <ChecDataPill
            v-for="option in options"
            :key="option.id"
            v-tooltip="showToolTip(option.name, 20)"
            class="variant-group-table__option"
            filled
            v-html="truncated(option.name, 20)"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { ChecDataPill } from '@chec/ui-library';

export default {
  name: 'GroupTable',
  components: {
    ChecDataPill,
  },
  props: {
    variantGroups: Array,
  },
  methods: {
    /**
     * If the option name is over the max, truncate the option and
     * add ellipsis. Doing so with CSS here does not seem to work.
     *
     * @param {string} str
     * @param {Number} max
     * @returns {string}
     */
    truncated(str, max) {
      return str.substr(0, max - 1)
        + (str.length > max ? '&hellip;' : '');
    },
    /**
     * If the string is greater than or equal to the max, return the string.
     *
     * @param {string} str
     * @param {Number} max
     * @returns {string|null}
     */
    showToolTip(str, max) {
      return str.length >= max
        ? str : null;
    },
  },
};
</script>

<style lang="scss">
.variant-group-table {
  @apply w-full mt-4;

  tr > *:nth-child(n + 2) {
    @apply text-right;
  }

  &__option {
    @apply inline-block;
  }
}
</style>
