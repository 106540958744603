<template>
  <ChecCard
    v-if="attributes.length > 0"
    inner-class="attributes-card"
    tailwind="p-4"
  >
    <ChecLoading v-if="loading" />
    <DashboardHeader
      variant="card"
      margin="small"
      :title="$t('attribute.attributes')"
    />

    <!-- Attributes fields -->
    <div class="attributes-card__fields-container">
      <div
        v-for="(attribute, index) in requiredAttributesOrder"
        :key="attribute.id"
        class="attributes-card__field"
      >
        <ChecFormField
          v-if="attribute.description"
          :tooltip="attribute.description"
          :append-label="$t('general.whatIsThis')"
        >
          <AttributesField
            :attribute="attribute"
            :value="getValue(attribute)"
            :errors="errors[index]"
            @input="value => setValue(attribute, value)"
          />
        </ChecFormField>
        <AttributesField
          v-else
          :attribute="attribute"
          :value="getValue(attribute)"
          :errors="errors[index]"
          @input="value => setValue(attribute, value)"
        />
      </div>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecFormField,
  ChecLoading,
} from '@chec/ui-library';
import crud from '@/mixins/crud';
import DashboardHeader from './DashboardHeader.vue';
import AttributesField from './Attributes/AttributesField.vue';

export default {
  name: 'AttributesCard',
  components: {
    ChecCard,
    ChecFormField,
    ChecLoading,
    DashboardHeader,
    AttributesField,
  },
  mixins: [
    crud('settings/attributes', true),
  ],
  model: {
    prop: 'attributeValues',
    event: 'change',
  },
  props: {
    attributeValues: {
      type: Array,
      required: true,
    },
    errors: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    /**
     * Attributes list ordered by `required` first
     *
     * @return {Array<Object>}
     */
    requiredAttributesOrder() {
      return [...this.attributes].sort((a, b) => {
        // Sort by `required` first with `a` taking precedence over b
        if (a.required && !b.required) {
          return -1;
        }
        if (!a.required && b.required) {
          return 1;
        }
        return 0;
      });
    },
  },
  async mounted() {
    await this.loadPage(1);
    this.loading = false;
  },
  methods: {
    /**
     * Get the value from the passed attributes based on the
     * attribute ID.
     *
     * @returns {String|Array}
     */
    getValue(attribute) {
      const valueDetail = this.attributeValues.find(
        (candidate) => candidate.id === attribute.id,
      );

      if (!valueDetail) {
        return attribute.default_value;
      }

      const { value } = valueDetail;

      // Check that the value exists on the option, if not return null
      if (['radio', 'options'].indexOf(attribute.type) >= 0 && value !== null) {
        const valExists = attribute.options.filter((opt) => value.includes(opt.value));

        if (!valExists.length) {
          return '';
        }
      }

      if (Array.isArray(value) && !attribute.is_multiselect) {
        return value[0];
      }

      // Null and empty strings are valid values
      return value !== undefined ? value : attribute.default_value;
    },
    /**
     * Update the changed value in the passed attributes.
     */
    setValue(attribute, value) {
      const attributeIndex = this.attributeValues.findIndex(
        (candidate) => candidate.id === attribute.id,
      );

      if (!this.attributeValues[attributeIndex]) {
        return;
      }

      this.$emit('change', [
        ...this.attributeValues.slice(0, attributeIndex),
        {
          ...this.attributeValues[attributeIndex],
          value,
        },
        ...this.attributeValues.slice(attributeIndex + 1),
      ]);
    },
  },
};
</script>

<style lang="scss">
.attributes-card {
  &__field {
    &:not(:first-of-type) {
      @apply pt-4;
    }
  }
}
</style>
