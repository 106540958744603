<template>
  <div class="variants">
    <ChecHeader header-tag="h3" :title="$t('product.variants.title')">
      <div v-if="!isLoading" class="variant-action-buttons space-x-2">
        <span v-tooltip.top="dirtyGroups ? $t('product.variants.mustSave') : null">
          <ChecButton
            variant="round"
            color="primary"
            icon="plus"
            :disabled="dirtyGroups"
            @click="addVariant"
          >
            {{ $t('general.add') }}
          </ChecButton>
        </span>
        <span v-tooltip.top="dirtyGroups ? $t('product.variants.mustSave') : null">
          <ChecButton
            variant="round"
            color="orange"
            icon="bullet-list"
            :disabled="dirtyGroups"
            @click="showPanel = 'bulk'"
          >
            {{ $t('product.variants.bulkAdd') }}
          </ChecButton>
        </span>
      </div>
    </ChecHeader>
    <ChecLoading v-if="isLoading && !showPanel" without-background />
    <!-- Appears when there are some groups and options set, but no variants -->
    <EmptyMessage
      v-else-if="!variants.length && !currentSearchTerm"
      :title="$t('product.variants.noVariantsTitle')"
    >
      <p>
        {{ $t('product.variants.noVariants') }}
      </p>
      <ChecAlert
        v-if="dirtyGroups"
        class="variants__requires-save"
        variant="info"
        disable-close
        show-icon
        inline
      >
        {{ $t('product.variants.requiresGroupsSave') }}
      </ChecAlert>
    </EmptyMessage>
    <template v-else>
      <ChecAlert
        v-if="showInvalidatingVariantsWarning"
        class="variants__invalidating-variants-warning"
        variant="warning"
        disable-close
        inline
      >
        <span>
          {{ $t('product.variants.willInvalidateVariants') }}
          <strong>{{ $t('product.variants.invalidVariantsOnSave') }}</strong>
        </span>
      </ChecAlert>
      <ChecAlert
        v-if="showHasInvalidWarning"
        class="variants__has-invalid-warning"
        variant="warning"
        disable-close
        inline
      >
        {{ $t('product.variants.hasInvalidVariants') }}
      </ChecAlert>
      <DashboardTable
        class="variant-table"
        :align-right-after="5"
        :columns="[
          { title: $t('product.variants.image'), class: 'variant-table__row-header-thumb' },
          { title: $tc('product.variants.options', 2), class: 'variant-table__row-header-options' },
          { title: $t('general.quantity'), class: 'variant-table__row-header-quantity' },
          { title: $t('general.price'), class: 'variant-table__row-header-price' },
          { title: $t('product.sku'), class: 'variant-table__row-header-sku' },
          { title: $t('general.actions'), class: 'variant-table__row-header-actions' },
        ]"
        :filters="filters"
        show-search
        state-key="products/variants"
        snug
        @change-filters="changeFilters"
      >
        <VariantRow
          v-for="variant in variants"
          :key="variant.id"
          :variant="models[variant.id]"
          :product-price="product.price"
          :deleted="isDeleted(variant.id)"
          @change="updateVariant"
          @delete="deleteVariant(variant.id)"
          @undo-delete="restoreVariant(variant.id)"
          @edit-variant="editVariant"
        />
      </DashboardTable>
    </template>
    <DashboardPagination
      keep-page-on-mount
      state-key="products/variants"
      :context="{ productId: product.id }"
    />
    <EditPanel
      v-if="showPanel === 'add' || showPanel === 'edit'"
      :variant="activeVariant"
      :product-variants="variants"
      :product-price="product.price"
      :product="product"
      :mode="showPanel"
      :currency-symbol="currencySymbol"
      @close="closeEditPanel"
      @open-editor="openEditPanel"
    />
    <BulkAddPanel
      v-if="showPanel === 'bulk'"
      :product="product"
      :variants="variants"
      @close="showPanel = ''"
    />
  </div>
</template>

<script>
import {
  ChecAlert,
  ChecButton,
  ChecHeader,
  ChecLoading,
} from '@chec/ui-library';
import { mapActions, mapState } from 'vuex';
import crud from '@/mixins/crud';
import DashboardPagination from '@/components/Pagination.vue';
import DashboardTable from '@/components/DashboardTable.vue';
import { actions } from '../../../store/variants';
import BulkAddPanel from './BulkAddPanel.vue';
import EmptyMessage from './EmptyMessage.vue';
import EditPanel from './EditPanel.vue';
import VariantRow from './VariantRow.vue';

export default {
  name: 'VariantTable',
  components: {
    DashboardTable,
    BulkAddPanel,
    ChecAlert,
    ChecButton,
    ChecHeader,
    ChecLoading,
    DashboardPagination,
    EditPanel,
    EmptyMessage,
    VariantRow,
  },
  mixins: [
    crud('products/variants', true),
  ],
  model: {
    prop: 'updatedVariants',
    event: 'change',
  },
  props: {
    // Tracks variants that have been deleted
    deletedVariants: Array,
    product: Object,
    showInvalidatingVariantsWarning: Boolean,
    // Tracks variants that have been edited
    updatedVariants: Array,
    // Tracks if the variant groups have changed
    dirtyGroups: Boolean,
  },
  data() {
    return {
      showPanel: '',
      // Store the currently being edited variant.
      activeVariant: {},
    };
  },
  computed: {
    ...mapState('merchant', ['merchant']),
    ...mapState('products/variants', ['invalidCount']),
    /**
     * The currency symbol that should be prefixed into the price input field.
     * Defaults to $ if the merchant isn't available in state for some reason.
     *
     * @returns {string}
     */
    currencySymbol() {
      return this.merchant.currency.symbol ?? '$';
    },
    /**
     * Converts the variant groups and options to filters that are used by the filter bar component
     */
    filters() {
      return this.product.variantGroups.map((group) => ({
        name: group.name,
        type: 'in',
        values: group.options.map(({ name }) => name),
      })).concat([{
        name: 'Validity',
        id: 'validity',
        type: 'in',
        values: ['Valid', 'Invalid'],
      }]);
    },
    /**
     * Collates variants that have been edited (in the updatedVariants array) with other variants
     * from the product.
     */
    models() {
      // Convert the variants on this page to an object map, keyed by variant ID
      return this.variants.reduce((acc, variant) => {
        // Find any draft copy of the variant and use that instead
        const draft = this.updatedVariants.find((candidate) => candidate.id === variant.id);

        if (draft) {
          return {
            ...acc,
            [draft.id]: draft,
          };
        }

        const options = Object.entries(variant.options).map(([groupId, optionId]) => {
          const group = this.product.variantGroups.find(({ id }) => id === groupId);
          const option = group.options.find(({ id }) => id === optionId);
          if (!option) {
            return null;
          }

          return {
            ...option,
            group,
          };
        }).filter((candidate) => candidate !== null);

        const price = variant.price === null ? { raw: null } : variant.price;

        return {
          ...acc,
          [variant.id]: { ...variant, options, price },
        };
      }, {});
    },
    showHasInvalidWarning() {
      return this.invalidCount !== null && this.invalidCount > 0;
    },
  },
  mounted() {
    if (!this.invalidCount && this.invalidCount !== 0) {
      this.loadInvalidCount();
    }
  },
  methods: {
    ...mapActions('products/variants', {
      loadInvalidCount: actions.LOAD_INVALID_COUNT,
    }),
    deleteVariant(variantId) {
      this.$emit('change-deleted-variants', [
        ...this.deletedVariants,
        variantId,
      ]);
    },
    isDeleted(variantId) {
      return this.deletedVariants.includes(variantId);
    },
    restoreVariant(variantId) {
      this.$emit('change-deleted-variants', this.deletedVariants.filter(
        (candidate) => candidate !== variantId,
      ));
    },
    changeFilters(filters) {
      // Convert the selection into filters used by pagination and trigger a search with new filters
      this.setFilters(filters.map(({ filter, id, value }) => {
        if (id === 'validity') {
          return {
            column: 'is_valid',
            type: 'exact',
            param: value === 'Valid',
            filterInUse: {
              filter,
              id,
              value,
            },
          };
        }

        const group = this.product.variantGroups.find(({ name }) => filter === name);

        return {
          query: 'option_ids',
          column: 'options',
          param: group.options.find(({ name }) => name === value).id,
          type: 'in',
          // Attach the group name and option name for easier use in the activeVariants prop
          filterInUse: {
            filter,
            id,
            value,
          },
        };
      }));
    },
    updateVariant(newValue) {
      // Find the variant that might've already been edited
      const existingIndex = this.updatedVariants.findIndex(
        (candidate) => candidate.id === newValue.id,
      );
      const variants = [...this.updatedVariants];

      // No match means we can just add the newly edited variant to our list
      if (existingIndex < 0) {
        variants.push(newValue);
      } else {
        // Otherwise we replace the existing edited variant with the newly changed one
        variants.splice(existingIndex, 1, newValue);
      }

      this.$emit('change', variants);
    },
    addVariant() {
      this.showPanel = 'add';
      this.activeVariant = {
        assets: [],
        description: '',
        price: '',
        sku: '',
        inventory: '',
        options: {},
        selectedOptions: {},
      };
    },
    /**
     * Open the editor panel and load selected variant.
     */
    editVariant(variantData) {
      this.showPanel = 'edit';
      this.activeVariant = variantData;
    },
    closeEditPanel() {
      this.showPanel = '';
      this.activeVariant = {};
    },
    /**
     * Open the edit panel from the add state when a variant exists.
     */
    openEditPanel(variantId) {
      this.showPanel = '';
      // Allow the close animation to play before switching to edit panel.
      this.$nextTick(() => {
        this.showPanel = 'edit';
        this.activeVariant = {
          ...this.models[variantId],
          selectedOptions: this.models[variantId].options,
        };
      });
    },
  },
};
</script>

<style lang="scss">
.variants {
  @apply relative;

  // Give room for the loading indicator - otherwise it overlays the title
  min-height: 12rem;

  &__invalidating-variants-warning {
    @apply mt-4;
  }

  &__has-invalid-warning {
    @apply mt-4;
  }

  &__requires-save {
    @apply mt-4;
  }
}

.variant-table {
  @apply mt-4;

  table {
    // h-px seems weird here - and it really is:
    // https://stackoverflow.com/questions/3215553/make-a-div-fill-an-entire-table-cell
    @apply h-px;
  }

  tbody {
    @apply h-full;
  }

  &__row-header-thumb,
  &__row-header-actions {
    @apply w-16;
  }

  &__row-header-sku {
    @apply w-1/5;
  }

  &__row-header-quantity {
    @apply w-24;
  }

  &__row-header-price {
    @apply w-40;
  }

  &__options {
    @apply px-2;
  }

  &__actions-button {
    @apply flex justify-end;
  }

  &__thumb {
    @include aspect-ratio(1, 1);
    @apply w-12 rounded mr-2;
  }

  &__no-image-thumbnail {
    @apply rounded w-12 h-12 flex items-center bg-gray-300 justify-center opacity-50 text-xxs mr-2;
  }

  // Override base table styling so the title aligns with the pills below it
  .variant-table__row-header-options {
    @apply px-3;
  }

  &__row--empty {
    @apply text-gray-400;
  }
}

.variant-action-buttons {
  @apply flex;
}
</style>
