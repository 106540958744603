<template>
  <ChecCard
    class="product-detail__card"
    inner-class="details-card"
  >
    <ChecHeader
      :title="$t('general.details')"
      variant="card"
      header-tag="h3"
    />
    <div class="input-wrapper">
      <TextField
        :value="product.name"
        name="name"
        :label="$t('product.name')"
        :variant="errors.name ? 'error' : ''"
        required
        @input="value => change('name', value)"
      />
      <span v-if="errors.name" class="input-wrapper__error">{{ errors.name }}</span>
    </div>
    <div class="input-wrapper__row space-x-2">
      <div class="input-wrapper">
        <TextField
          :value="product.sku"
          name="sku"
          :label="$t('product.sku')"
          @input="value => change('sku', value)"
        />
      </div>
      <div class="input-wrapper product-detail__inventory-available">
        <ChecFormField
          :tooltip="$t('product.inventory.tooltip')"
          :append-label="$t('general.whatIsThis')"
        >
          <TextField
            :value="product.inventory.available"
            name="inventory_available"
            :disabled="!product.inventory.managed"
            :label="$t('product.inventory.available')"
            :variant="!product.inventory.managed ? 'disabled' : errors.quantity ? 'error' : ''"
            @input="value => handleChangeInventory('available', value)"
          >
            <ChecSwitch
              prefix-label
              :toggled="product.inventory.managed"
              name="inventory_managed"
              @input="value => handleChangeInventory('managed', value)"
            >
              {{ $t('product.inventory.managed') }}
            </ChecSwitch>
          </TextField>
        </ChecFormField>
        <span v-if="errors['inventory.available']" class="input-wrapper__error">
          {{ errors['inventory.available'] }}
        </span>
      </div>
    </div>
    <div class="input-wrapper">
      <ChecWysiwyg
        :value="product.description"
        name="description"
        :label="$t('product.description')"
        @input="value => changeDescription(value)"
      />
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecFormField,
  ChecHeader,
  ChecSwitch,
  TextField,
} from '@chec/ui-library';
import ChecWysiwyg from '@chec/ui-library/dist/ChecWysiwyg';

export default {
  name: 'DetailsCard',
  components: {
    ChecCard,
    ChecFormField,
    ChecHeader,
    ChecSwitch,
    ChecWysiwyg,
    TextField,
  },
  model: {
    prop: 'product',
    event: 'input',
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    change(prop, value) {
      this.$emit('input', {
        ...this.product,
        [prop]: value,
      });
    },
    changeDescription(value) {
      let description = value;
      if (value === '<p></p>') {
        description = '';
      }
      this.$emit('input', {
        ...this.product,
        description,
      });
    },
    /**
     * Change handler for inventory settings
     */
    handleChangeInventory(option, value) {
      this.change('inventory', {
        ...this.product.inventory,
        [option]: value,
      });
    },
  },
};
</script>

<style lang="scss">
.details-card {
  @apply p-4;

  .chec-header {
    @apply mb-4;
  }
}

.product-detail {
  &__inventory-available {
    .text-field--disabled .text-field__right-content {
      @apply opacity-100; // by default this would be .5
    }
  }
}
</style>
