<template>
  <ChecCard tailwind="p-4">
    <ChecHeader
      variant="card"
      :title="$t('product.variants.title')"
    >
      <ChecButton
        color="primary"
        :icon="cardActionIcon"
        variant="round"
        @click="handleOpenPanel"
      >
        {{ cardActionLabel }}
      </ChecButton>
    </ChecHeader>
    <GroupTable
      v-if="variants.length"
      :variant-groups="variants"
    />
    <p v-else class="variants-card__help-text">
      {{ $t('product.variants.help') }}
    </p>
    <ManagePanel
      v-if="showPanel"
      :product="product"
      @change-variant-groups="emitChange"
      @close="handleClosePanel"
      @save="$emit('save')"
      @saved="$emit('saved')"
    />
    <ChecAlert
      v-if="errors.variantGroups"
      class="images-card__alert"
      variant="error"
      inline
      disable-close
    >
      {{ errors.variantGroups }}
    </ChecAlert>
  </ChecCard>
</template>

<script>
import {
  ChecAlert,
  ChecButton,
  ChecCard,
  ChecHeader,
} from '@chec/ui-library';
import { mapActions } from 'vuex';
import actions from '@/lib/pagination/actions';
import GroupTable from './VariantsCard/GroupTable.vue';
import ManagePanel from './VariantsCard/ManagePanel.vue';

export default {
  name: 'VariantsCard',
  components: {
    ChecAlert,
    ChecButton,
    ChecCard,
    ChecHeader,
    GroupTable,
    ManagePanel,
  },
  model: {
    prop: 'product', // V-model product change
    event: 'change',
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    errors: Object,
  },
  data() {
    return {
      showPanel: false,
    };
  },
  computed: {
    variants() {
      return this.product.variantGroups;
    },
    cardActionLabel() {
      if (!this.variants.length) {
        return this.$t('general.add');
      }
      return this.$t('general.edit');
    },
    cardActionIcon() {
      if (!this.variants.length) {
        return 'plus';
      }
      return '';
    },
  },
  mounted() {
    this.setContext({ context: { productId: this.product.id } });
  },
  methods: {
    ...mapActions('products/variants', { setContext: actions.SWITCH_CONTEXT }),
    handleOpenPanel() {
      this.showPanel = true;
    },
    handleClosePanel() {
      this.showPanel = false;
    },
    emitChange(variantGroups) {
      this.$emit('change', { ...this.product, variantGroups });
    },
  },
};
</script>

<style lang="scss">
.variants-card {
  &__variant {
    @apply mt-2;

    &:first-of-type {
      @apply mt-4;
    }
  }

  &__help-text {
    @apply mt-4;
  }
}
</style>
