export default function resolveSelectedOptionsByGroup(selectedOptions, variantGroups) {
  return Object.values(selectedOptions.reduce((acc, optionId) => {
    // Find the group for the option, by finding the option within a group
    let option;
    const group = variantGroups.find((candidateGroup) => {
      option = candidateGroup.options.find(
        (candidateOption) => candidateOption.id === optionId,
      );
      return Boolean(option);
    });

    // Find the existing array for the group, or create a new array if it doesn't exist
    const existingGroup = acc[group.id] || [];

    // Merge our option into the existing group and set the group key
    return {
      ...acc,
      [group.id]: [
        ...existingGroup,
        // Give the option a "group" key
        {
          ...option,
          group,
        },
      ],
    };
  }, {}));
}
