<template>
  <ChecCard
    class="product-detail__card"
    inner-class="details-card"
  >
    <ChecHeader
      :title="$t('product.price')"
      variant="card"
      header-tag="h3"
    />
    <div class="input-wrapper">
      <TextField
        :value="product.price.toString().replace(',', '')"
        name="price"
        :label="priceFieldLabel"
        :currency-symbol="currencySymbol"
        currency
        :variant="errors.price ? 'error' : ''"
        :required="!isPwyw"
        @input="value => change('price', value)"
      >
        <ChecSwitch
          prefix-label
          :toggled="product.conditionals.is_pay_what_you_want"
          name="is_pay_what_you_want"
          @input="handleChangePriceOption"
        >
          {{ $t('product.payWhatYouWant') }}
        </ChecSwitch>
      </TextField>
      <span v-if="errors.price" class="input-wrapper__error">{{ errors.price }}</span>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecHeader,
  ChecSwitch,
  TextField,
} from '@chec/ui-library';

export default {
  name: 'PriceCard',
  components: {
    ChecCard,
    ChecHeader,
    ChecSwitch,
    TextField,
  },
  model: {
    prop: 'product',
    event: 'input',
  },
  props: {
    product: {
      type: Object,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    currencySymbol: String,
  },
  computed: {
    isPwyw() {
      return this.product.conditionals.is_pay_what_you_want;
    },
    priceFieldLabel() {
      return this.isPwyw
        ? this.$t('product.minimumPrice')
        : this.$t('product.price');
    },
  },
  methods: {
    change(prop, value) {
      this.$emit('input', {
        ...this.product,
        [prop]: value,
      });
    },
    /**
     * Change handler for the conditionals options switch
     */
    handleChangePriceOption(value) {
      this.change('conditionals', {
        ...this.product.conditionals,
        is_pay_what_you_want: value,
      });
    },
  },
};
</script>

<style lang="scss">
.details-card {
  @apply p-4;

  .chec-header {
    @apply mb-4;
  }
}
</style>
