<template>
  <ChecCard inner-class="variant-group-option">
    <div class="input-wrapper">
      <div class="variant-group-option__name-price">
        <div class="variant-group-option__name-field">
          <TextField
            v-model="name"
            :option="errors.name ? 'error' : ''"
            :label="$tc('product.variants.options', 1)"
          />
        </div>
        <div class="variant-group-option__price-field">
          <ChecFormField :tooltip="$t('product.variants.defaultPriceTooltip')">
            <TextField
              v-model="amount"
              :currency-symbol="currencySymbol"
              currency
              :label="$t('product.variants.defaultPrice')"
            />
          </ChecFormField>
        </div>
      </div>
    </div>
    <span v-if="errors.name" class="input-wrapper__error">{{ errors.name }}</span>
    <p>{{ $t('product.variants.optionDefaultImage') }}</p>
    <ChecImageManager
      v-model="assets"
      class="images-card__dropzone"
      :normalise-file="normaliseAsset"
      :endpoint="resolveEndpoint"
      :accept-successful-upload="completeUpload"
      :image-gallery="productAssets"
      :footnote="footnote"
      accepted-files="image/jpeg,image/png,image/gif,image/jpg"
    />
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecFormField,
  TextField,
  ChecImageManager,
} from '@chec/ui-library';
import upload from '@/mixins/upload';

export default {
  name: 'OptionCard',
  components: {
    ChecCard,
    ChecFormField,
    TextField,
    ChecImageManager,
  },
  mixins: [upload],
  model: {
    prop: 'option',
    event: 'change',
  },
  props: {
    option: Object,
    currencySymbol: String,
    errors: {
      type: Object,
      default: () => ({}),
    },
    productAssets: Array,
  },
  data() {
    return {
      trackedImages: [],
    };
  },
  computed: {
    name: {
      get() {
        return this.option.name;
      },
      set(name) {
        this.$emit('change', { ...this.option, name });
      },
    },
    amount: {
      get() {
        return this.option.price !== null && this.option.price.formatted
          ? this.option.price.formatted.replace(',', '')
          : '';
      },
      set(amount) {
        this.$emit('change', {
          ...this.option,
          price: {
            ...this.option.price,
            raw: amount,
            formatted: amount.toString(),
          },
        });
      },
    },
    /**
     * v-model for the image manager. Note that option assets are only given as IDs by the API, so
     * we need to convert this into the actual image objects (available on the product) for use.
     * Additionally, while we have unsaved images in the field, they don't exist on the product
     */
    assets: {
      get() {
        const productAssetIds = this.productAssets.map(({ id }) => id);

        // Concatenate the product assets, and "trackedImages" that represent images seen by the
        // image manager - ie. those that have been uploaded and added, but not saved, along with
        // the product images that might already be attached.
        // We need to filter out the images that are already known by the product to avoid
        // duplicates
        return this.productAssets
          .concat(this.trackedImages.filter(({ id }) => !productAssetIds.includes(id)))
          .filter(({ id }) => (this.option.assets || []).includes(id));
      },
      set(assets) {
        this.trackedImages = assets;
        this.$emit('change', { ...this.option, assets: assets.map(({ id }) => id) });
      },
    },
    footnote() {
      return this.$tc('product.images.types', 3, {
        types: 'PNG, JPG',
        lastType: 'GIF',
      });
    },
  },
};
</script>

<style lang="scss">
.variant-group-option {
  &__name-price {
    @apply flex w-full;
  }

  &__name-field {
    @apply w-1/2 mr-4;
  }

  &__price-field {
    @apply w-1/2;
  }
}
</style>
