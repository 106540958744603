<template>
  <ChecSlideoutPanel
    :title="$t('product.variants.createBulk')"
    close-on-overlay-click
    @close="$emit('close')"
  >
    <ChecDropdown
      v-model="selectedOptions"
      :label="$t('product.variants.chooseGroupsAndOptions')"
      :options="dropdownOptions"
      multiselect
      required
    />
    <h3 class="variants-add-bulk__preview-title">
      {{ $t('product.variants.preview') }}
    </h3>
    <p>
      {{
        $t(`product.variants.${hasSelectedInEachGroup ? 'previewHelp' : 'optionsInGroupsRequired'}`)
      }}
    </p>
    <ChecTable v-if="hasSelectedInEachGroup" inner-class="variants-add-bulk__preview-table">
      <thead>
        <tr>
          <th>{{ $tc('product.variants.options', 2) }}</th>
          <th>{{ $t('product.price') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="previewVariants.length === 0">
          <td colspan="2" class="variants-add-bulk__no-variants-to-generate">
            {{ $t('product.variants.noVariantsToGenerate') }}
          </td>
        </tr>
        <tr v-for="({ options, price }, index) in previewVariants" :key="index">
          <td>
            <OptionPills :options="options" without-groups />
          </td>
          <td>
            {{ merchant.currency.symbol }}{{ price.toFixed(2) }}
          </td>
        </tr>
        <!-- This is purposefully 1, as it's written so it'll never hide just one variant -->
        <tr v-if="additionalPreviewCount > 1">
          <td colspan="2">
            {{ $t('product.variants.andMore', { count: additionalPreviewCount }) }}
          </td>
        </tr>
      </tbody>
    </ChecTable>
    <template #toolbar>
      <div class="manage-variants__toolbar space-x-4">
        <ChecButton text-only color="primary" @click="$emit('close')">
          {{ $t('general.cancel' ) }}
        </ChecButton>
        <ChecButton
          color="gray"
          :disabled="variantsToCreate.length === 0 || saving"
          @click="save"
        >
          {{
            saving
              ? $t('general.creating')
              : $tc('product.variants.create', variantsToCreate.length)
          }}
        </ChecButton>
      </div>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import {
  ChecButton,
  ChecDropdown,
  ChecSlideoutPanel,
  ChecTable,
} from '@chec/ui-library';
import { mapActions, mapState } from 'vuex';
import addNotification from '@/mixins/addNotification';
import { actions } from '@/modules/products/store/variants';
import OptionPills from './OptionPills.vue';
import resolveSelectedOptionsByGroup from '../../../lib/resolveSelectedOptionsByGroup';
import generateVariants from '../../../lib/generateVariants';

const PREVIEW_VARIANT_COUNT = 15;

export default {
  name: 'BulkAddPanel',
  components: {
    OptionPills,
    ChecButton,
    ChecDropdown,
    ChecSlideoutPanel,
    ChecTable,
  },
  mixins: [
    addNotification,
  ],
  props: {
    product: Object,
    variants: Array,
  },
  data() {
    return {
      maxPreviewVariants: PREVIEW_VARIANT_COUNT,
      saving: false,
      // Default the selected options to everything
      selectedOptions: this.product.variantGroups.reduce(
        (acc, group) => [...acc, ...group.options.map(({ id }) => id)],
        [],
      ),
    };
  },
  computed: {
    ...mapState('merchant', ['merchant']),
    /**
     * Calculates the number of variants there are that cannot be previewed in the table (as there
     * are too many to display)
     */
    additionalPreviewCount() {
      return this.variantsToCreate.length > this.maxPreviewVariants;
    },
    /**
     * Creates an array of options for the multi-select dropdown
     */
    dropdownOptions() {
      return this.product.variantGroups.map((group) => ({
        value: group.id,
        label: group.name,
        group: group.options.map((option) => ({
          value: option.id,
          label: option.name,
        })),
      }));
    },
    /**
     * Figures out whether the user has chosen at least one option in each group
     */
    hasSelectedInEachGroup() {
      return this.selectedOptions.length > 0
        && this.selectedOptionsByGroup.length === this.product.variantGroups.length;
    },
    /**
     * Converts all "variants to create" into a (potentially) subset of variants with only their
     * options and a calculated default price for each variant
     */
    previewVariants() {
      // Show the first 15 variants as previews (or 16 if there's exactly 16)
      const candidates = this.variantsToCreate <= this.maxPreviewVariants + 1
        ? this.variantsToCreate
        : this.variantsToCreate.slice(0, this.maxPreviewVariants);

      return candidates.map((options) => ({
        options,
        price: parseFloat(this.product.price) + options.reduce(
          (sum, { price }) => (
            sum + (price && typeof price === 'object' ? price.raw : parseFloat(price))
          ),
          0,
        ),
      }));
    },
    /**
     * Converts the selected options in the dropdown to a map of arrays of selected options keyed by
     * the group ID that the options belong to
     */
    selectedOptionsByGroup() {
      return resolveSelectedOptionsByGroup(this.selectedOptions, this.product.variantGroups);
    },
    /**
     * Parses the selected options (by the user) into a list of all variant combinations that should
     * be created based on the choices. Filters out any variants that already exist on the product
     */
    variantsToCreate() {
      return generateVariants(this.selectedOptions, this.product.variantGroups, this.variants);
    },
  },
  methods: {
    ...mapActions('products/variants', {
      createVariants: actions.BULK_CREATE,
    }),
    async save() {
      this.saving = true;

      // When we bulk create variants, the API only wants an array of option IDs
      try {
        await this.createVariants({
          variants: this.variantsToCreate.map((options) => ({
            options: options.map(({ id }) => id),
          })),
        });
        this.addNotification(this.$t('product.variants.bulkSuccess'));

        // Auto close the panel on success
        this.$emit('close');
      } catch (error) {
        this.addNotification(this.$t('product.variants.bulkFailure'), 'error');
      }
    },
  },
};
</script>

<style lang="scss">
.variants-add-bulk {
  &__preview-title {
    @apply mt-8 mb-4 font-bold text-xl leading-none;
  }

  &__preview-table {
    @apply w-full mt-4 text-right;

    th,
    td {
      &:first-child {
        @apply text-left;
      }
    }
  }

  &__no-variants-to-generate {
    @apply text-gray-400;
  }
}
</style>
