<template>
  <form v-if="!appIsLoading" class="product-detail" @submit.prevent>
    <!-- Portaled to DetailsPageNavigator -->
    <DetailsPagination
      v-if="productId"
      :item="productId"
      route-name="products.edit"
      state-key="products"
    />
    <ChecHeader
      :title="isNew ? $t('product.add') : $t('product.edit')"
      class="product-detail__header"
    >
      <Timestamp
        v-if="!isNew && product.last_updated"
        :timestamp="product.last_updated - 3600 * 24"
        :prefix="$t('product.lastSavedAt')"
        from-now
      />
    </ChecHeader>
    <div class="product-detail__column-container">
      <div class="product-detail__column product-detail__column--sticky">
        <VerticalNavigation
          :has-attributes="attributes.length !== 0"
        />
      </div>
      <div class="product-detail__column">
        <DetailsCard
          id="details"
          v-model="product"
          :errors="validationErrors"
        />
        <PriceCard
          id="price"
          v-model="product"
          :currency-symbol="currencySymbol"
          :errors="validationErrors"
        />
        <AttributesCard
          id="attributes"
          v-model="product.attributes"
          :errors="validationErrors && validationErrorObject.attributes"
        />
        <VariantsCard
          id="variants"
          v-model="product"
          :currency-symbol="currencySymbol"
          :errors="validationErrors"
          @save="handleSaveProduct"
          @saved="() => updateProductState(true)"
        />
        <ImagesCard
          id="images"
          v-model="product.assets"
          :product="product"
        />
        <ShippingCard
          id="shipping"
          v-model="product"
          :product="product"
          :currency-symbol="currencySymbol"
          :validation-errors="validationErrors"
        />
        <DigitalFulfillmentCard
          id="digital-delivery"
          v-model="product.fulfillment.digital"
          :has-digital-delivery="product.conditionals.has_digital_delivery"
          @toggle="handleToggleDigitalFulfillment"
        />
        <ExtraFieldsCard
          id="extra-fields"
          :extra-fields="product.extra_fields"
          :collect="product.collects"
          :errors="validationErrorObject.extra_fields || []"
          @change-extra-fields="handleEditExtraFields"
          @change-option="handleChangeCollectsOption"
        />
        <SeoCard id="seo" v-model="product" :errors="validationErrors" />
        <MiscCard id="misc" v-model="product" :errors="validationErrors" />
      </div>
      <div class="product-detail__column product-detail__column--sticky">
        <!-- SideOptions -->
        <ActionsCard
          allow-delete
          :active="product.active"
          :is-new="isNew"
          :saving="isSaving"
          @save-product="handleSaveProduct"
          @clone-product="handleCloneProduct"
          @delete-product="handleDeleteProduct"
          @toggle-active="(active) => { product.active = active; }"
        />
        <CategoryCard v-model="product.categories" />
        <RelatedProductsCard
          v-model="product.related_products"
          :product-id="productId"
        />
      </div>
    </div>
  </form>
</template>

<script>
import { ChecHeader } from '@chec/ui-library';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import dot from 'dot-object';
import {
  array, object, mixed, string,
} from 'yup';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import addNotification from '@/mixins/addNotification';
import confirm from '@/mixins/confirm';
import crud from '@/mixins/crud';
import AttributesCard from '@/components/AttributesCard.vue';
import Timestamp from '@/components/Timestamp.vue';
import DetailsPagination from '@/components/DetailsPagination.vue';
import mutations from '@/store/mutations';
import productSchema from '../schemas/product';
import cards from '../components/cards';
import VerticalNavigation from '../components/VerticalNavigation.vue';
import ActionsCard from '../components/ActionsCard.vue';

export default {
  name: 'ProductEdit',
  components: {
    ...cards,
    DetailsPagination,
    Timestamp,
    ActionsCard,
    AttributesCard,
    ChecHeader,
    VerticalNavigation,
  },
  mixins: [
    addNotification,
    confirm,
    crud('products'),
    crud('settings/attributes', true, null, true),
  ],
  data() {
    return {
      initiallyLoaded: this.isLoading,
      isSaving: false,
      isCloning: false,
      cloneProduct: {},
      product: {
        active: true,
        assets: [],
        attributes: [],
        name: '',
        sku: '',
        description: '',
        inventory: {
          managed: false,
          available: '',
        },
        price: '',
        conditionals: {
          has_physical_delivery: false,
          has_digital_delivery: false,
          is_pay_what_you_want: false,
        },
        sort_order: 0,
        thank_you_url: '',
        permalink: '',
        extra_fields: [],
        collects: {
          billing_address: false,
          shipping_address: false,
          fullname: false,
          extra_fields: false,
        },
        fulfillment: {
          digital: {
            access_rules: {
              duration: 0,
              expires: false,
              limit: null,
              period: null,
            },
            assets: [],
          },
          physical: [],
        },
        seo: {
          title: '',
          description: '',
        },
        variants: [],
        variantGroups: [],
        categories: [],
        related_products: [],
        last_updated: null,
      },
      validationErrors: {},
    };
  },
  computed: {
    ...mapState('merchant', ['merchant']),
    ...mapGetters({ appIsLoading: 'isLoading' }),
    isNew() {
      return this.$route.name === 'products.add';
    },
    productId() {
      return this.$route.params.id;
    },
    rawProduct() {
      return this.get(this.productId);
    },
    /**
     * The currency symbol that should be prefixed into the price input field.
     * Defaults to $ if the merchant isn't available in state for some reason.
     *
     * @returns {string}
     */
    currencySymbol() {
      return this.merchant.currency.symbol ?? '$';
    },
    validationErrorObject() {
      if (!this.validationErrors) {
        return {};
      }

      return dot.object({ ...this.validationErrors });
    },
  },
  watch: {
    /**
     * Updates the product state if the route params change.
     * We need to do this because the product will not be able to automatically
     * update the product if only the url params change.
     */
    '$route.params.id': function routeIdWatcher() {
      this.assertFullyLoaded();
    },
  },
  created() {
    this.assertFullyLoaded();
  },
  mounted() {
    // Ensure attributes is loaded
    this.loadPage(1);
  },
  methods: {
    ...mapActions('fulfillment', ['loadPhysicalOptions']),
    ...mapMutations([mutations.SET_LOADING]),
    /**
     * List view product responses may not contain all the data we need for
     * the edit form. This method ensures that edit forms will fully fetch
     * product data when it is required.
     */
    assertFullyLoaded() {
      const refreshProductIfRequired = () => {
        if (this.isNew) {
          return Promise.resolve();
        }

        // Load the product from state...
        return this.load(this.productId).then(() => {
          // Check that the product contains the fulfillment data
          const product = this.get(this.productId);
          if (!product.fulfillment) {
            // Load the full product individually from the API, and return true from the promise to
            // indicate that we should force an update of the modified product state
            return this.load(this.productId, true).then(() => true);
          }
          // Return false to indicate the modified product state doesn't need to be forced
          return false;
        });
      };

      this[mutations.SET_LOADING](true);

      Promise.all([
        refreshProductIfRequired(),
        this.loadPageAttributes(1),
        this.loadPhysicalOptions(),
      ]).then(([force]) => {
        this.updateProductState(force);
      }).catch((error) => {
        // Handle 404s
        if (error?.request?.status === 404) {
          this.$router.push({ name: 'notFound' });
        }
      }).finally(() => {
        this[mutations.SET_LOADING](false);
      });
    },
    /**
     * Handle changing of an extra field value
     */
    handleEditExtraFields(extraFields) {
      this.product.extra_fields = extraFields;
    },
    handleChangeCollectsOption(prop, value) {
      this.product.collects[prop] = value;
    },
    handleToggleDigitalFulfillment(enabled) {
      this.product.conditionals.has_digital_delivery = enabled;
    },
    /**
     * Updates the known state of the product in this component.
     *
     * @param {Boolean} force Force the getter to refetch state from VueX rather than relying on the
     *                        computed property updates.
     */
    updateProductState(force = false) {
      if (this.isNew) {
        // We still want to polyfill missing attribute values
        this.product.attributes = this.fillAttributes([]);
        return;
      }
      const rawProduct = force ? this.get(this.productId) : this.rawProduct;

      if (!rawProduct) {
        return;
      }

      const {
        active,
        attributes,
        assets,
        id,
        name,
        price,
        sku,
        description,
        inventory,
        permalink,
        sort_order: sortOrder,
        thank_you_url: thankYouUrl,
        extra_fields: extraFields,
        collects,
        seo,
        conditionals,
        fulfillment,
        variants,
        variant_groups: variantGroups,
        categories,
        last_updated: lastUpdated,
        related_products: relatedProducts,
      } = rawProduct;
      this.product.id = id;
      this.product.attributes = this.fillAttributes(attributes);
      this.product.active = active;
      this.product.assets = assets;
      this.product.name = name;
      this.product.price = price.formatted ? price.formatted.toString().replace(',', '') : '';
      this.product.sku = sku;
      this.product.inventory = inventory;
      this.product.description = description;
      this.product.permalink = permalink;
      this.product.extra_fields = extraFields;
      this.product.collects = collects;
      this.product.sort_order = sortOrder;
      this.product.thank_you_url = thankYouUrl;
      this.product.seo = seo;
      this.product.conditionals = conditionals;
      this.product.variants = variants;
      this.product.variantGroups = variantGroups;
      this.product.categories = categories;
      this.product.related_products = relatedProducts;
      this.product.fulfillment.physical = fulfillment ? fulfillment.physical : [];
      this.product.fulfillment.digital = fulfillment ? fulfillment.digital : {};
      this.product.last_updated = lastUpdated;
    },
    /**
     * Set product to clone mode and save.
     */
    handleCloneProduct() {
      this.isCloning = true;
      // Create a copy of the product pbject in the event of saving failure.
      this.cloneProduct = this.product;

      this.cloneProduct.name = this.product.name;
      this.cloneProduct.permalink = '';
      // Map the extra fields to remove ID's
      this.cloneProduct.extra_fields = this.product.extra_fields.map(
        (field) => ({ name: field.name, require: field.required }),
      );

      // Map the variant groups to remove ID's
      this.cloneProduct.variantGroups = this.product.variantGroups.map(
        (group) => ({
          name: group.name,
          options: group.options.map((option) => ({
            name: option.name,
            price: option.price || null,
            assets: option.assets || null,
          })),
        }),
      );
      this.cloneProduct.variants = [];

      this.handleSaveProduct();
    },
    /**
     * Validate then update the product.
     */
    handleSaveProduct() {
      this.validationErrors = {};
      this.isSaving = true;

      // Add required validation for attributes, based on whether the attribute is actually required
      // or not
      const newSchema = productSchema.shape({
        attributes: array().of(
          object({
            id: string(),
            value: this.attributes.reduce((schema, attribute) => {
              if (!attribute.required) {
                return schema;
              }

              return schema.when('id', {
                is: attribute.id,
                then: mixed().required(this.$t('validation.enterValue')),
              });
            }, mixed()),
          }),
        ),
      });

      const expectedErrors = [[422, {
        'product.permalink': 'Provided permalink is already in use',
      }]];

      validateSchemaRequest(
        newSchema,
        this.isCloning ? this.cloneProduct : this.product,
        { abortEarly: false },
      )
        .then(
          (validatedData) => (this.isNew || this.isCloning
            ? this.create(validatedData, true, true, expectedErrors)
            : this.update(this.productId, validatedData, true, true, expectedErrors)),
        )
        .then(({ id }) => {
          this.$nextTick(() => {
            // Force the getter to run when re-fetching state here.
            this.updateProductState(true);
          });
          this.addNotification(
            this.isCloning ? this.$t('product.duplicated') : this.$t('product.saved'),
          );
          if (this.isNew || this.isCloning) {
            // Was this the merchant's first product? If so, refetch merchant info
            if (!this.merchant?.statistics?.products) {
              this.$store.dispatch('merchant/FETCH_MERCHANT', {
                showLoading: false,
                force: true,
              });
            }
            // Send the user back to the edit screen for the new product
            this.$router.push({ name: 'products.edit', params: { id } });
          }
        })
        .catch((error) => {
          if (error && error.name === 'ValidationError') { // yup schema validation error
            this.validationErrors = error.errors;
            return;
          }

          if (error.response?.status === 422) {
            // Flatten all the errors into a one dimensional list
            const errors = Object.entries(error.response.data.error?.errors || {})
              .reduce((acc, [field, messages]) => ([
                ...acc,
                ...messages.map((message) => ({ field, message })),
              ]), []);

            // Use errors that we expect, and turn them into something than can be used with the
            // validationErrors prop
            this.validationErrors = errors.reduce((acc, { field, message }) => {
              if (field === 'product.permalink') {
                return {
                  ...acc,
                  permalink: message,
                };
              }
              return acc;
            }, {});
          }

          this.addNotification(
            this.$t('product.saveFailed'),
            'error',
          );
        })
        .finally(() => {
          this.isSaving = false;
          this.isCloning = false;
          this.cloneProduct = {};
        });
    },
    /**
     * Confirm and delete the product.
     */
    async handleDeleteProduct() {
      if (!await this.confirm(
        this.$t('product.confirmDeleteTitle'),
        this.$t('product.confirmDelete'),
      )) {
        return;
      }

      // Send user immediately back to product list
      const originalRoute = this.$router.currentRoute;
      const originalProductId = this.productId;
      await this.$router.push({ name: 'products.home' });

      if (originalProductId === undefined) {
        // Product wasn't saved yet, do nothing.
        return;
      }

      this.delete(originalProductId)
        .then(() => {
          this.addNotification(this.$t('product.deleted'));
        })
        .catch((error) => {
          this.addNotification(this.$t('product.deleteFailed'), 'error');
          this.$router.push(originalRoute); // send user back to product view
          throw error;
        });
    },
    /**
     * Takes the attribute values given by the API, and converts them to be just the values and not
     * the label that the API also gives us. For example, the API will provide
     * { value: 2, label: 'two' } as the "value" of an attribute, but we just want it to be "2".
     *
     * @param {Array<Object>} attributeValues
     * @returns {Array<Object>}
     */
    fillAttributes(attributeValues) {
      if (!this.attributes) {
        return [];
      }

      return this.attributes.reduce((acc, attribute) => {
        const existingValue = attributeValues.find((candidate) => candidate.id === attribute.id);

        if (existingValue) {
          if (['radio', 'options'].includes(attribute.type)) {
            // If the default value is null, don't send that as [null]
            let attributeValue = existingValue.value.map(({ value }) => value);
            if (attributeValue.length === 1 && attributeValue[0] === null) {
              attributeValue = null;
            }

            return [...acc, {
              ...existingValue,
              value: attributeValue,
            }];
          }

          return [...acc, existingValue];
        }

        // If the default value is null, don't send that as [null]
        const value = attribute.is_multiselect && attribute.default_value
          ? [attribute.default_value]
          : attribute.default_value;

        return [...acc, {
          id: attribute.id,
          value,
        }];
      }, []);
    },
  },
};
</script>

<style lang="scss">
.product-detail {
  @apply text-gray-500;

  &__header {
    @apply mb-8;
  }

  &__column-container {
    @apply flex -mx-4;
  }

  &__column {
    @apply px-4 w-6/11 flex flex-col py-1 -mt-1;

    &:first-of-type {
      @apply w-2/11;
    }

    &:last-of-type {
      @apply w-3/11;
    }

    &--sticky {
      @apply sticky overflow-auto h-full;
      top: 20px; // offset from the top of screen while sticky
    }
  }

  &__card .card__inner-wrapper {
    @apply p-4;
  }

  .card {
    @apply mb-8;
  }
}
</style>
