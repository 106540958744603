<template>
  <ChecCard
    class="product-detail__card"
    inner-class="shipping-card"
  >
    <div class="shipping-card__header">
      <ChecHeader
        :title="$t('product.shippingCard.title')"
        variant="card"
        header-tag="h3"
      />
      <div class="input-wrapper">
        <ChecSwitch
          prefix-label
          :toggled="product.conditionals.has_physical_delivery"
          name="has_physical_delivery"
          @input="handleChangeEnabled"
        >
          {{ stateLabel }}
        </ChecSwitch>
      </div>
    </div>
    <div v-if="product.conditionals.has_physical_delivery && !loadingRates">
      <Zone
        v-for="zone in zones"
        :key="zone.id"
        :shipping-options="shippingOptions[zone.id]"
        :zone="zone"
        :product-rates="product.fulfillment.physical"
        :currency-symbol="currencySymbol"
        :validation-errors="validationErrors"
        @change="(option) => handleChangeZone(zone.id, option)"
      />
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecHeader,
  ChecSwitch,
} from '@chec/ui-library';
import { mapState } from 'vuex';
import crud from '@/mixins/crud';
import Zone from './ShippingCard/Zone.vue';

export default {
  name: 'ShippingCard',
  components: {
    ChecCard,
    ChecHeader,
    ChecSwitch,
    Zone,
  },
  mixins: [
    crud('fulfillment/zones', true),
  ],
  props: {
    product: {
      type: Object,
    },
    currencySymbol: String,
    validationErrors: Object,
  },
  computed: {
    ...mapState('fulfillment/rates', { loadingRates: 'isLoading' }),
    /**
     * The shipping options that are available to be configured, e.g. anything that the
     * merchant sets up in "Settings > Shipping".
     */
    shippingOptions() {
      return this.zones.reduce((acc, zone) => ({
        ...acc,
        [zone.id]: this.physicalFulfillments.filter((rate) => rate.zone_id === zone.id),
      }), {});
    },
    /**
     * The shipping options that have been enabled for this specific product.
     */
    physicalFulfillments() {
      return Array.isArray(this.product.fulfillment.physical)
        ? this.product.fulfillment.physical : [];
    },
    /**
     * Whether shipping is enabled or not
     *
     * @returns {string}
     */
    stateLabel() {
      return this.product.conditionals.has_physical_delivery
        ? this.$t('general.enabled')
        : this.$t('general.disabled');
    },
  },
  methods: {
    /**
     * Handler for when prices in a zone are changed, or a zone is disabled.
     *
     * @param {string} zoneId
     * @param {object[]} value
     */
    handleChangeZone(zoneId, value) {
      // All zones excluding the zoneId provided
      const otherZones = [...this.product.fulfillment.physical]
        .filter((option) => option.zone_id !== zoneId);

      // An empty value indicates that the zone was disabled, so remove it
      if (!value.length) {
        this.$emit('input', {
          ...this.product,
          fulfillment: {
            ...this.product.fulfillment,
            physical: otherZones,
          },
        });

        // If there are no enabled zones, also disable "shipping options"
        if (otherZones.length === 0) {
          this.handleChangeEnabled(false);
        }
        return;
      }

      // Take the array of new zone/rate combos, and replace existing combos
      // with the new value where the zone ID matches the provided one.
      this.$emit('input', {
        ...this.product,
        fulfillment: {
          ...this.product.fulfillment,
          physical: otherZones.concat(value),
        },
      });
    },
    change(prop, value) {
      this.$emit('input', {
        ...this.product,
        [prop]: value,
      });
    },
    /**
     * Handles toggling whether shipping options are enabled for the current product
     *
     * @param {boolean} value
     */
    handleChangeEnabled(value) {
      this.change('conditionals', {
        ...this.product.conditionals,
        has_physical_delivery: value,
      });
    },
  },
};
</script>

<style lang="scss">
.shipping-card {
  @apply p-4;

  &__header {
    @apply flex flex-row;

    .chec-switch {
      @apply float-right mt-1;
    }
  }

  &__item {
    @apply bg-gray-200 mt-2;

    &:first-of-type {
      @apply mt-4;
    }
  }

  &__item-form {
    @apply flex flex-row mt-4;

    &:not(:first-of-type) {
      @apply pt-4 border-t border-solid border-gray-300;
    }

    .form-field {
      @apply mr-1;

      &:nth-of-type(2),
      &:last-of-type {
        @apply mr-0;
      }
    }
  }

  &__item-form-seperator {
    @apply w-8 h-8 m-2;
  }

  .chec-header {
    @apply mb-0;
  }
}
</style>
