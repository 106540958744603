import resolveSelectedOptionsByGroup from './resolveSelectedOptionsByGroup';

export default function generateVariants(selectedOptions, variantGroups, existingVariants) {
  const variants = [];
  const selectedOptionsByGroup = resolveSelectedOptionsByGroup(selectedOptions, variantGroups);
  const hasSelectedInEachGroup = selectedOptions.length > 0
    && selectedOptionsByGroup.length === variantGroups.length;

  if (!hasSelectedInEachGroup) {
    return variants;
  }

  // We need to create a recursive loop here - as the number of variant groups is set by the
  // merchant. This function will return a loop for use with Array.forEach, and with either
  // recursively loop, or push potential variants onto the loop
  //
  // The function takes a list of variant group to loop through, and existing options that are
  // being looped through from prior groups. Each time it creates a loop, it will shift a group
  // from the list, and loop through the options in that group
  const loop = ([group, ...remainingGroups], options = []) => (option) => {
    if (group) {
      group.forEach(loop(remainingGroups, [...options, option]));
      return;
    }

    const newVariant = [...options, option];

    // Figure out if this variant already exists, and don't push it into the list if it does
    if (
      existingVariants.find(({ options: candidateOptions }) => (
        newVariant.every(
          ({ id }) => Object.values(candidateOptions).includes(id),
        )
      ))
    ) {
      return;
    }

    variants.push(newVariant);
  };

  const [initialGroup, ...groups] = selectedOptionsByGroup;

  if (!initialGroup) {
    return variants;
  }

  initialGroup.forEach(loop(groups));

  return variants;
}
