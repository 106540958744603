<template>
  <ChecCard
    tailwind="p-4"
    inner-class="digital-fulfillment-card"
  >
    <ChecHeader
      variant="card"
      :title="$t('product.images.title')"
    />
    <ChecImageManager
      v-model="files"
      class="images-card__dropzone"
      :normalise-file="normaliseAsset"
      :endpoint="resolveEndpoint"
      :accept-successful-upload="completeUpload"
      accepted-files="image/jpeg,image/png,image/gif,image/jpg,image/webp"
      :footnote="footnote"
    />
    <ChecAlert
      v-if="showAlert"
      class="images-card__alert"
      variant="info"
      inline
      disable-close
      show-icon
    >
      {{ $t('product.images.alert') }}
    </ChecAlert>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecImageManager,
  ChecHeader,
  ChecAlert,
} from '@chec/ui-library';
import upload from '@/mixins/upload';

export default {
  name: 'ImagesCard',
  components: {
    ChecCard,
    ChecImageManager,
    ChecHeader,
    ChecAlert,
  },
  mixins: [upload],
  model: {
    prop: 'images',
    event: 'change',
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    product: Object,
  },
  computed: {
    files: {
      get() {
        return this.images;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
    footnote() {
      return this.$tc('product.images.types', 3, {
        types: 'PNG, JPG',
        lastType: 'GIF',
      });
    },
    showAlert() {
      const { variantGroups } = this.product;
      return this.images.length > 0 && variantGroups.length > 0;
    },
  },
};
</script>

<style lang="scss">
.images-card {
  &__dropzone {
    @apply mt-4;
  }

  &__alert {
    @apply mt-4;
  }
}
</style>
