<template>
  <ChecModal :header="$t('product.embed')" @dismiss="dismiss">
    <h3 class="share-embed__subtitle">
      {{ $t('product.embedShareSell') }}
    </h3>
    <p class="share-embed__copy">
      {{ $t('product.linkCustomers') }}
    </p>
    <div class="share-embed__link">
      <a :href="shareUrl" target="_blank" rel="noopener nofollow">
        {{ shareUrl }}
      </a>

      <CopyButton
        class="share-embed__copy-button"
        :copy-content="shareUrl"
      />
    </div>

    <hr class="share-embed__divider">

    <h3 class="share-embed__subtitle">
      {{ $t('product.embedCheckout') }}
    </h3>
    <p class="share-embed__copy">
      {{ $t('product.embedCheckoutDescription') }}

      <CopyButton
        class="share-embed__copy-button"
        :copy-content="shareCode"
      />
    </p>

    <!-- eslint-disable -->
    <CodeBlock class="share-embed__code" language="html" grow>{{ shareCode }}</CodeBlock>
    <!-- eslint-enable -->

    <p>{{ $t('product.copyPaste') }}</p>
  </ChecModal>
</template>

<script>
import { ChecModal, CodeBlock } from '@chec/ui-library';
import resolveDomain from '@/lib/resolveDomain';
import CopyButton from '@/components/CopyButton.vue';

export default {
  name: 'ShareEmbed',
  components: {
    ChecModal,
    CodeBlock,
    CopyButton,
  },
  props: {
    permalink: {
      type: String,
      required: true,
    },
  },
  computed: {
    /**
     * @returns {string}
     */
    shareUrl() {
      return `${resolveDomain('checkout')}/${this.permalink}`;
    },
    /**
     * Annoying that we have to escape the <> characters here. If someone finds
     * a better solution please re-do it.
     *
     * @returns {string}
     */
    shareCode() {
      const code = '&lt;script src="https://cdn.chec.io/js/embed.js"&gt;&lt;/script&gt;\n'
        + `&lt;a href="${this.shareUrl}"\n`
        + `data-chec-product-id="${this.permalink}"&gt;Buy now&lt;/a&gt;`;

      return code
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>');
    },
  },
  methods: {
    /**
     * Proxy event from ChecModal through to parent
     */
    dismiss() {
      this.$emit('dismiss');
    },
  },
};
</script>

<style lang="scss">
.share-embed {
  &__subtitle {
    @apply text-sm font-bold mb-2;
  }

  &__copy {
    @apply flex items-baseline justify-between;
  }

  &__copy,
  &__code {
    @apply mb-4;
  }

  &__code {
    @apply mt-4;
  }

  &__copy-button {
    @apply float-right;
  }

  &__link {
    @apply bg-white rounded-sm p-4;

    a {
      @apply text-blue-600;
    }
  }

  &__divider {
    @apply border-gray-300 my-8;
  }
}
</style>
