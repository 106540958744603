<template>
  <tr class="variant-group-row">
    <td class="variant-group-row__name">
      <div class="input-wrapper">
        <div class="variant-group-row__name-field">
          <TextField
            ref="name"
            v-model="name"
            name="variant-group-name"
            :placeholder="$t('product.variants.variantGroupName')"
            :variant="textFieldVariant"
          />
        </div>
        <span
          v-if="errors[`item.variantGroups[${groupIndex}].name`]"
          class="input-wrapper__error"
        >
          {{ errors[`item.variantGroups[${groupIndex}].name`] }}
        </span>
      </div>
    </td>
    <td class="variant-group-row__options">
      <div class="variant-group-row__options-field">
        <ChecTagsField
          :tags="options"
          add-on-blur
          :add-on-keys="[9, 13, 188]"
          :disabled="deleted"
          :max-length="20"
          :placeholder="$t('product.variants.addOption')"
          @change="updateOptions"
        />
        <span
          v-if="errors[`item.variantGroups[${groupIndex}].options`]"
          class="input-wrapper__error"
        >
          {{ errors[`item.variantGroups[${groupIndex}].options`] }}
        </span>
      </div>
    </td>
    <td class="variant-group-row__actions">
      <ChecButton
        v-if="deleted"
        class="variant-group-row__cancel"
        variant="round"
        color="primary"
        outline
        @click="cancelDelete"
      >
        {{ $t('general.cancel') }}
      </ChecButton>
      <ChecOptionsMenu v-else class="variant-group-row__actions-buttons">
        <ChecOption
          :option="{ disabled: !group.options.length }"
          @option-selected="showPanel = true"
        >
          {{ $t('general.advanced') }}
        </ChecOption>
        <ChecOption destructive @option-selected="$emit('delete')">
          {{ group.id ? $t('general.delete') : $t('general.cancel') }}
        </ChecOption>
      </ChecOptionsMenu>
      <ManageOptionsPanel
        v-if="showPanel"
        :group="group"
        :product="product"
        :currency-symbol="merchant.currency.symbol"
        @close="showPanel = false"
        @change-group-options="emitChange"
        @saved="$emit('saved')"
      />
    </td>
  </tr>
</template>

<script>
import {
  ChecButton,
  ChecOption,
  ChecOptionsMenu,
  ChecTagsField,
  TextField,
} from '@chec/ui-library';
import { mapState } from 'vuex';
import ManageOptionsPanel from './ManageOptionsPanel.vue';

export default {
  name: 'VariantGroupRow',
  components: {
    ChecButton,
    ChecOption,
    ChecOptionsMenu,
    ChecTagsField,
    ManageOptionsPanel,
    TextField,
  },
  props: {
    deleted: Boolean,
    product: Object,
    group: Object,
    errors: Object,
    focusOnMount: Boolean,
    groupIndex: Number,
  },
  data() {
    return {
      showPanel: false,
    };
  },
  computed: {
    ...mapState('merchant', ['merchant']),
    /**
     * Computed attributes that serve as v-models for the inputs in the row
     */
    name: {
      get() {
        return this.group.name;
      },
      set(name) {
        this.$emit('change', {
          ...this.group,
          name,
        });
      },
    },
    /**
     * @description Returns an array of the options' names
     * @returns {Array}
     */
    options() {
      return this.group.options.map((option) => option.name);
    },
    textFieldVariant() {
      if (this.deleted) {
        return 'disabled';
      }

      if (this.errors[`item.variantGroups[${this.groupIndex}].name`]) {
        return 'error';
      }

      return '';
    },
  },
  mounted() {
    if (!this.focusOnMount) {
      return;
    }

    // Find and focus the name input (the first input in the row)
    this.$refs.name.$el.querySelector('input').focus();
  },
  methods: {
    cancelDelete() {
      this.$emit('change', { ...this.group, deleted: false });
    },
    // Update existing options with new options
    // by comparing the existing and new options list
    updateOptions(newOptions) {
      // Make a copy of the current existing options
      const existingOptions = [...this.group.options];
      // Check to see if an option was removed by checking
      // if the existing options is more than the new options
      if (existingOptions.length > newOptions.length) {
        // Find the index of the missing option index
        // by checking that the new options does not
        // include the name of the option
        const missingOptionIndex = existingOptions.findIndex(
          (candidate) => !newOptions.includes(candidate.name),
        );
        // Remove the option at the index position by 1
        existingOptions.splice(missingOptionIndex, 1);
        // Emit the change event to update the options list
        this.$emit('change', { ...this.group, options: existingOptions });
        return;
      }
      // Check to see if a new option was added by checking
      // if the existing options is less than the new options
      if (existingOptions.length < newOptions.length) {
        // Return the new option that is not included
        // in the current list of options
        const newOption = newOptions.find(
          (candidate) => !this.options.includes(candidate),
        );
        // Emit change by updating the existing options
        // and setting the newOption name and default
        // values to price and qty properties
        this.$emit('change', {
          ...this.group,
          options: [
            ...existingOptions,
            {
              name: newOption,
              price: { raw: 0 }, // Price will be set in the advanced panel
            },
          ],
        });
      }
    },
    emitChange(groupOptions) {
      this.$emit('change', { ...this.group, options: groupOptions });
    },
  },
};
</script>

<style lang="scss">
.variant-group-row {
  @apply w-full;

  &__name {
    @apply w-2/6 align-top;

    .input-wrapper {
      @apply mb-0;
    }
  }

  &__name-field,
  &__options-field {
    @apply mr-4;
  }

  &__options {
    @apply align-top;
  }

  &__options-field {
    // Fixes a height mismatch between empty field and one with a tag in it
    .tags-field__input-wrapper {
      @apply mb-px;
      margin-top: calc(0.5rem + 3px);
    }
  }

  &__actions {
    @apply flex justify-end;
  }

  &__actions-buttons {
    @apply mt-2;
  }

  &__cancel {
    @apply ml-auto;
  }
}
</style>
