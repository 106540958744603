<template>
  <div class="products-home">
    <DashboardHeader :title="$t('product.products')">
      <ChecButton
        color="primary"
        variant="round"
        icon="plus"
        @click="() => $router.push({ name: 'products.add' })"
      >
        {{ $t('general.add') }}
      </ChecButton>
    </DashboardHeader>

    <IntroSplash
      v-if="showIntroSplash"
      :title="$t('product.introSplash.title')"
      button
      @click="() => $router.push({ name: 'products.add' })"
    >
      <i18n path="product.introSplash.description" tag="span">
        <template #api>
          <a href="https://commercejs.com/docs/api/#create-product" target="_blank">
            {{ $t('product.introSplash.api') }}
          </a>
        </template>
        <template #more>
          <a href="http://support.commercejs.com/en/articles/513221-adding-a-product" target="_blank">
            {{ $t('product.introSplash.more') }}
          </a>
        </template>
      </i18n>
    </IntroSplash>

    <ProductsTable
      v-else
      :products="products"
      @delete-product="confirmAndDeleteProduct"
      @share-product="handleShareProduct"
    />

    <Pagination state-key="products" />

    <ShareEmbed
      v-if="sharePermalink"
      :permalink="sharePermalink"
      @dismiss="handleCloseShareProduct"
    />
  </div>
</template>

<script>
import { ChecButton } from '@chec/ui-library';
import addNotification from '@/mixins/addNotification';
import confirm from '@/mixins/confirm';
import crud from '@/mixins/crud';
import DashboardHeader from '@/components/DashboardHeader.vue';
import IntroSplash from '@/components/IntroSplash.vue';
import Pagination from '@/components/Pagination.vue';
import ProductsTable from '../components/ProductsTable.vue';
import ShareEmbed from '../components/ShareEmbed.vue';

export default {
  name: 'ProductsHome',
  components: {
    ChecButton,
    DashboardHeader,
    IntroSplash,
    Pagination,
    ProductsTable,
    ShareEmbed,
  },
  mixins: [
    addNotification,
    crud('products', true),
    confirm,
  ],
  data() {
    return {
      sharePermalink: null,
    };
  },
  computed: {
    /**
     * Show intro card section when there are no products and when
     * page is not loading
     *
     * @returns {boolean}
     */
    showIntroSplash() {
      return this.products.length === 0 && !this.isLoading && this.currentSearchTerm?.length === 0;
    },
  },
  methods: {
    /**
     * Open a modal showing share/embed information for the product
     *
     * @param {string} permalink
     */
    handleShareProduct(permalink) {
      this.sharePermalink = permalink;
    },
    /**
     * Close the share/embed dialog
     */
    handleCloseShareProduct() {
      this.sharePermalink = null;
    },
    async confirmAndDeleteProduct(productId) {
      if (!await this.confirm(
        this.$t('product.confirmDeleteTitle'),
        this.$t('product.confirmDelete'),
      )) {
        return;
      }

      this.delete(productId)
        .then(() => this.addNotification(this.$t('product.deleted')))
        .catch(() => this.addNotification(this.$t('product.deleteFailed'), 'error'));
    },
  },
};
</script>

<style lang="scss" scoped>
.products-home {
  @apply w-full;

  &__search {
    @apply mb-4;
  }
}
</style>
