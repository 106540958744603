<template>
  <ChecCard
    class="product-detail__card"
    inner-class="misc-card"
  >
    <ChecHeader
      :title="$t('product.misc')"
      variant="card"
      header-tag="h3"
    />

    <div class="input-wrapper">
      <TextField
        :value="product.thank_you_url"
        name="thank_you_url"
        :label="$t('product.thankYouPage')"
        :variant="errors.thank_you_url ? 'error' : ''"
        placeholder="https://"
        @input="value => change('thank_you_url', value)"
      />
      <span v-if="errors.thank_you_url" class="input-wrapper__error">
        {{ errors.thank_you_url }}
      </span>
    </div>

    <div class="input-wrapper">
      <ChecFormField
        :tooltip="$t('product.sortOrderTooltip')"
        :append-label="$t('general.whatIsThis')"
      >
        <TextField
          :value="product.sort_order"
          name="sort_order"
          :label="$t('product.sortOrder')"
          :variant="errors.sort_order ? 'error' : ''"
          placeholder="0"
          type="number"
          @input="value => change('sort_order', value)"
        />
        <span v-if="errors.sort_order" class="input-wrapper__error">
          {{ errors.sort_order }}
        </span>
      </ChecFormField>
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecFormField,
  ChecHeader,
  TextField,
} from '@chec/ui-library';

export default {
  name: 'MiscCard',
  components: {
    ChecCard,
    ChecFormField,
    ChecHeader,
    TextField,
  },
  model: {
    prop: 'product',
    event: 'input',
  },
  props: {
    product: {
      type: Object,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    change(prop, value) {
      this.$emit('input', {
        ...this.product,
        [prop]: value,
      });
    },
  },
};
</script>

<style lang="scss">
.misc-card {
  @apply p-4;

  .chec-header {
    @apply mb-4;
  }
}
</style>
