<template>
  <ChecCard
    class="product-detail__card"
    inner-class="related-products-card"
  >
    <ChecLoading v-if="isLoading" />
    <ChecHeader
      :title="$t('product.relatedProducts')"
      variant="card"
    />
    <ResourceDropdown
      v-if="availableItemCount > 0 && !isLoading"
      v-model="productsData"
      name="product.related_products"
      state-key="products"
      :exclude="[productId]"
      :placeholder="$t('product.chooseRelatedProducts')"
      multiselect
    />
    <template v-else>
      {{ messageCopy }}
    </template>
  </ChecCard>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  ChecCard,
  ChecHeader,
  ChecLoading,
} from '@chec/ui-library';
import ResourceDropdown from '@/components/ResourceDropdown.vue';
import actions from '@/lib/pagination/actions';

export default {
  name: 'RelatedProductsCard',
  components: {
    ChecCard,
    ChecHeader,
    ChecLoading,
    ResourceDropdown,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    productId: {
      type: String,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // Triggered when the component first mounts, and used to decide whether
      // to render the ResourceDropdown
      isLoading: true,
      // We track the total number that's un-searched, as total count provided by the pagination
      // library shows the total number matching the current search
      unsearchedTotal: null,
    };
  },
  computed: {
    ...mapState('products', ['totalCount']),
    messageCopy() {
      return this.isLoading
        ? this.$t('general.loading')
        : this.$t('product.noRelatedAvailable');
    },
    productsData: {
      /**
       * Extract the ID out of the related products objects
       *
       * @returns {string[]}
       */
      get() {
        return this.value.map(({ id }) => id);
      },
      set(value) {
        this.$emit('change', value.map((id) => ({ id })));
      },
    },
    availableItemCount() {
      if (typeof this.productId !== 'undefined') {
        // Exclude the current product as an option (when the current product actually exists)
        return this.unsearchedTotal - 1;
      }
      return this.unsearchedTotal;
    },
  },
  mounted() {
    // Prefetch. We need to do this because the ResourceDropdown won't
    // fetch anything if we don't render it.
    this.loadProducts().then(() => {
      this.isLoading = false;
      this.unsearchedTotal = this.totalCount;
    });
  },
  methods: {
    ...mapActions('products', { loadProducts: actions.LOAD_REMAINING_ITEMS }),
  },
};
</script>

<style lang="scss">
.related-products-card {
  @apply p-4;

  .chec-header {
    @apply mb-4;
  }
}
</style>
