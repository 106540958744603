<template>
  <ChecSlideoutPanel
    :title="groupTitle"
    close-on-overlay-click
    @close="close"
  >
    <div class="manage-options__options-wrapper">
      <OptionCard
        v-for="(option, index) in groupOptions"
        :key="option.id || index"
        :option="option"
        :currency-symbol="currencySymbol"
        :errors="optionErrors[index] || {}"
        :product-assets="product.assets"
        @change="(newOption) => updateOption(option.id || index, newOption)"
      />
    </div>
    <template #toolbar>
      <div class="manage-options__add-option">
        <ChecButton
          variant="round"
          icon="plus"
          color="primary"
          @click="addOption"
        >
          Add option
        </ChecButton>
      </div>
      <div class="manage-options__toolbar space-x-4">
        <ChecButton text-only color="primary" @click="close">
          {{ $t('general.cancel' ) }}
        </ChecButton>
        <ChecButton
          v-if="group.options.length > 0"
          color="primary"
          :disabled="saving"
          @click="save"
        >
          {{ saving ? $t('general.saving') : $t('general.saveChanges') }}
        </ChecButton>
      </div>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import { mapActions } from 'vuex';
import {
  ChecButton,
  ChecSlideoutPanel,
} from '@chec/ui-library';
import { actions as paginationActions } from '@/lib/pagination';
import addNotification from '@/mixins/addNotification';
import OptionCard from './OptionCard.vue';

export default {
  name: 'ManageOptionsPanel',
  components: {
    ChecButton,
    ChecSlideoutPanel,
    OptionCard,
  },
  mixins: [
    addNotification,
  ],
  model: {
    prop: 'group',
    event: 'change',
  },
  props: {
    product: Object,
    group: Object,
    currencySymbol: String,
    errors: Object,
  },
  data() {
    return {
      saving: false,
      updatedGroup: { ...this.group },
    };
  },
  computed: {
    /**
     * @returns {Array}
     */
    groupOptions() {
      return this.updatedGroup.options;
    },
    /**
     * @returns {Array}
     */
    optionErrors() {
      if (!this.errors) {
        return [];
      }

      return Array.isArray(this.errors.options) ? this.errors.options : [];
    },
    /**
     * @returns {String}
     */
    groupTitle() {
      if (!this.group.name) {
        return this.$t('product.variants.variantGroup');
      }
      return this.$t('product.variants.variantGroupTitle', { group: this.group.name });
    },
  },
  methods: {
    ...mapActions('products', {
      saveProduct: paginationActions.UPDATE_ITEM,
    }),
    /**
     * Add an new group option card
     */
    addOption() {
      this.updatedGroup = {
        ...this.updatedGroup,
        options: [
          ...this.updatedGroup.options,
          {
            id: null,
            name: '',
            price: { raw: '' },
            assets: [],
          },
        ],
      };
    },
    findGroupOptionIndex(id) {
      return typeof id === 'string' && id.startsWith('optn')
        ? this.group.options.findIndex((candidate) => candidate.id === id)
        : id;
    },
    updateOption(id, option) {
      const groupOptions = [...this.updatedGroup.options];
      groupOptions.splice(this.findGroupOptionIndex(id), 1, option);
      this.updatedGroup = {
        ...this.updatedGroup,
        options: groupOptions,
      };
    },
    async save() {
      // Set saving to true when saving
      this.saving = true;

      // Save group and options within internal panel state
      this.saveProduct({
        id: this.product.id,
        item: {
          id: this.product.id,
          variantGroups: [
            // Filter through variant groups and update with new array
            // excluding current updatedGroup
            ...this.product.variantGroups.filter(
              (candidate) => candidate.id !== this.updatedGroup.id,
            ),
            this.updatedGroup,
          ],
        },
        merge: false,
        optimistic: false,
      }).then(() => {
        this.addNotification(this.$t('product.variants.optionsUpdateSuccess'));
        this.$emit('saved');
      }).catch(() => {
        this.addNotification(this.$t('product.variants.optionsUpdateFailure'), 'error');
      }).finally(() => {
        this.saving = false;
      });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.manage-options {
  &__toolbar {
    @apply w-full flex justify-end items-center;
  }

  &__add-option {
    @apply w-full;
  }
}
</style>
