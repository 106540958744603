import i18n from '@/lang';
import {
  string, object, array,
} from 'yup';

export default object().shape({
  id: string().required(),
  item: object({
    variantGroups: array().of(
      object({
        name: string().required(i18n.t('validation.enterName')),
        options: array().of(
          object({
            name: string().required(i18n.t('validation.enterOption')),
          }),
        ).min(1, i18n.t('product.variants.noOptions')),
      }),
    ),
  }),
});
