<template>
  <ChecCard
    tailwind="p-4"
    inner-class="digital-fulfillment-card"
  >
    <ChecHeader
      variant="card"
      :title="$t('product.digitalFulfillment.title')"
    >
      <ChecSwitch prefix-label :toggled="hasDigitalDelivery" @input="handleToggle">
        {{ hasDigitalDelivery ? $t('general.enabled') : $t('general.disabled') }}
      </ChecSwitch>
    </ChecHeader>
    <template v-if="hasDigitalDelivery">
      <ChecFileUploader
        v-model="files"
        class="digital-fulfillment-card__dropzone"
        :normalise-file="normaliseAsset"
        :endpoint="getEndpoint"
        :accept-successful-upload="completeUpload"
        :additional-dropzone-options="{
          // Max of 4 GiB uploads
          maxFilesize: 4096,
          // 1hr upload timeout
          timeout: 3600000,
        }"
        @dropzone-event="handleDropzoneEvent"
      />
      <TextField
        :value="downloadLimit"
        :placeholder="$t('general.unlimited')"
        type="number"
        :label="$t('product.digitalFulfillment.downloadLimit')"
        @input="value => { updateAccess('limit', true)(value) }"
      />
      <ChecAccordion
        class="digital-fulfillment-card__expiration-settings"
        variant="switch"
        :title="$t('product.digitalFulfillment.linkExpiration')"
        :subtitle="linkExpirationSubtitle"
        :open="accessRules.expires"
        @toggled="value => { updateAccess('expires')(value) }"
      >
        <div
          v-if="accessRules.expires"
          class="digital-fulfillment-card__expiration-settings-inner"
        >
          <TextField
            :value="accessRules.duration"
            type="number"
            required
            :label="$t('product.digitalFulfillment.duration')"
            @input="value => { updateAccess('duration', true)(value) }"
          />
          <ChecDropdown
            :value="accessRules.period || 'days'"
            :label="$t('product.digitalFulfillment.durationPeriod')"
            :options="periodOptions"
            required
            @input="value => { updateAccess('period')(value) }"
          />
        </div>
      </ChecAccordion>
    </template>
  </ChecCard>
</template>

<script>
import {
  ChecAccordion,
  ChecCard,
  ChecDropdown,
  ChecHeader,
  ChecFileUploader,
  ChecSwitch,
  TextField,
} from '@chec/ui-library';
import * as Sentry from '@sentry/browser';
import upload from '@/mixins/upload';

export default {
  name: 'DigitalFulfillmentCard',
  components: {
    ChecAccordion,
    ChecCard,
    ChecDropdown,
    ChecHeader,
    ChecFileUploader,
    ChecSwitch,
    TextField,
  },
  mixins: [upload],
  props: {
    value: {
      type: [Object, Array],
      required: true,
    },
    hasDigitalDelivery: Boolean,
  },
  computed: {
    accessRules() {
      return this.value.access_rules || {
        duration: null,
        expires: false,
        limit: null,
        period: 'days',
      };
    },
    downloadLimit() {
      if (this.accessRules.limit === 'unlimited') {
        return null;
      }
      return this.accessRules.limit;
    },
    files: {
      get() {
        return Array.isArray(this.value.assets) ? this.value.assets : [];
      },
      set(files) {
        this.$emit('input', { ...this.value, assets: files });
      },
    },
    linkExpirationSubtitle() {
      const { duration, expires, period } = this.accessRules;

      if (!expires || !duration) {
        return this.$t('product.digitalFulfillment.doesNotExpire');
      }

      return this.$t('product.digitalFulfillment.expiryMessage', {
        duration,
        period: this.$tc(
          `product.digitalFulfillment.period.${period}`,
          duration,
        ),
      });
    },
    periodOptions() {
      const { duration } = this.accessRules;
      return [
        { value: 'days', label: this.$tc('product.digitalFulfillment.period.days', duration) },
        { value: 'weeks', label: this.$tc('product.digitalFulfillment.period.weeks', duration) },
        { value: 'months', label: this.$tc('product.digitalFulfillment.period.months', duration) },
      ];
    },
  },
  methods: {
    /**
     * Override the resolveEndpoint method provided by the mixin to add Sentry logging
     * @param file
     * @returns {Promise}
     */
    getEndpoint(file) {
      Sentry.addBreadcrumb({
        type: 'debug',
        message: 'Digital fulfillment asset added. '
          + `Name: ${file.name}, Type: ${file.type}, Size: ${file.size}`,
        level: Sentry.Severity.Info,
      });

      return this.resolveEndpoint(file);
    },
    updateAccess(rule, number = false) {
      return (value) => {
        this.$emit('input', {
          ...this.value,
          access_rules: {
            ...this.accessRules,
            [rule]: number && value < 1 ? '' : value,
          },
        });
      };
    },
    handleToggle(toggled) {
      this.$emit('toggle', toggled);
    },
    handleDropzoneEvent(event, file, errorMessage) {
      if (event !== 'error') {
        return;
      }

      Sentry.captureException(`File upload failure: ${errorMessage}`);
    },
  },
};
</script>

<style lang="scss">
.digital-fulfillment-card {
  &__dropzone {
    @apply my-4;
  }

  &__expiration-settings {
    @apply mt-4;
  }

  &__expiration-settings-inner {
    @apply grid grid-cols-2 gap-2;
  }
}
</style>
