<template>
  <ChecVerticalNavigation class="product-detail--sticky">
    <div v-for="item in navItems" :key="item.label">
      <ChecVerticalNavItem
        v-if="!item.hideItem"
        :to="item.hash"
        @click.native="checkHashScroll"
      >
        {{ item.label }}
      </ChecVerticalNavItem>
    </div>
  </ChecVerticalNavigation>
</template>

<script>
import { ChecVerticalNavigation, ChecVerticalNavItem } from '@chec/ui-library';

export default {
  name: 'VerticalNavigation',
  components: {
    ChecVerticalNavigation,
    ChecVerticalNavItem,
  },
  props: {
    hasAttributes: Boolean,
  },
  data() {
    return {
      navItems: [
        {
          label: this.$t('general.details'),
          hash: '#details',
          showItem: false,
        },
        {
          label: this.$t('product.price'),
          hash: '#price',
          hideItem: false,
        },
        {
          label: this.$t('attribute.attributes'),
          hash: '#attributes',
          hideItem: !this.hasAttributes,
        },
        {
          label: this.$t('product.variants.title'),
          hash: '#variants',
          hideItem: false,
        },
        {
          label: this.$t('product.images.title'),
          hash: '#images',
          hideItem: false,
        },
        {
          label: this.$t('product.shippingCard.title'),
          hash: '#shipping',
          hideItem: false,
        },
        {
          label: this.$t('product.digitalFulfillment.title'),
          hash: '#digital-delivery',
          hideItem: false,
        },
        {
          label: this.$t('extraFields.extraFields'),
          hash: '#extra-fields',
          hideItem: false,
        },
        {
          label: this.$t('product.seo'),
          hash: '#seo',
          hideItem: false,
        },
        {
          label: this.$t('product.misc'),
          hash: '#misc',
          hideItem: false,
        },
      ],
    };
  },
  methods: {
    /**
     * Allows for scrolling to card element
     * even if at current route
     */
    checkHashScroll() {
      // Check that window location hash is the same as the router hash
      if (window.location.hash === this.$route.hash) {
        // Get the card element id of the router hash
        const el = document.getElementById(this.$route.hash.slice(1));
        // Allows scroll to card when click event is attached
        if (el) {
          // Scroll to element and add 80px to offset relative position
          // eliminate extra padded top pos when scrolled
          window.scrollTo(0, (el.offsetTop + 80));
        }
      }
    },
  },
};
</script>
