<template>
  <div class="variant-option-list space-x-2">
    <template v-for="option in options">
      <ChecDataPill v-if="withoutGroups" :key="option.id" filled>
        <div
          v-tooltip="showToolTip(option.name, 20)"
          class="variant-option-list__copy"
        >
          {{ option.name }}
        </div>
      </ChecDataPill>
      <ChecDataPillGroup v-else :key="option.id">
        <ChecDataPill v-if="!withoutGroups" filled>
          <div
            v-tooltip="showToolTip(option.group.name, 20)"
            class="variant-option-list__copy"
          >
            {{ option.group.name }}
          </div>
        </ChecDataPill>
        <ChecDataPill>
          <div
            v-tooltip="showToolTip(option.name, 20)"
            class="variant-option-list__copy"
          >
            {{ option.name }}
          </div>
        </ChecDataPill>
      </ChecDataPillGroup>
    </template>
  </div>
</template>

<script>
import {
  ChecDataPill,
  ChecDataPillGroup,
} from '@chec/ui-library';

export default {
  name: 'OptionPills',
  components: {
    ChecDataPill,
    ChecDataPillGroup,
  },
  props: {
    options: Array,
    withoutGroups: Boolean,
  },
  methods: {
    showToolTip(str, max) {
      return str.length >= max
        ? str : null;
    },
  },
};
</script>

<style lang="scss">
.variant-option-list {
  @apply flex;

  &__copy {
    @apply overflow-hidden whitespace-no-wrap;

    max-width: 125px;
    text-overflow: ellipsis;
  }
}
</style>
