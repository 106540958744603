<template>
  <ChecSlideoutPanel
    :title="mode === 'add' ? $t('product.variants.addVariant') : variantTitle"
    close-on-overlay-click
    @close="close"
  >
    <ChecLoading v-if="isLoading || loading" />
    <template>
      <ChecAlert
        v-if="variant.id && !variant.is_valid"
        class="variants-edit__invalid-alert"
        variant="info"
        disable-close
        inline
      >
        {{ $t(`product.variants.invalidReason.${variant.invalid_reason_code}`) }}
      </ChecAlert>
      <div v-if="Object.keys(selectedOptions).length">
        <DashboardHeader
          :title="$t('product.variants.selectOptions')"
          margin="small"
          header-tag="h3"
        />
        <div
          class="variants-edit__options-listing space-x-2"
        >
          <div
            v-for="group in product.variantGroups"
            :key="group.id"
            class="variants-edit__option"
          >
            <ChecDropdown
              v-model="selectedOptions[group.id]"
              :label="group.name"
              :options="sanitizeOptions(group.options)"
              placeholder="Select option"
              required
            />
          </div>
        </div>
        <ChecAlert
          v-if="existingVariant
            && !saving
            && existingVariant.id !== updatedVariant.id"
          class="variants-edit__alert"
          variant="warning"
          disable-close
          inline
        >
          <span class="variants-edit__alert-copy">
            <span>
              {{ $t('product.variants.variantExists') }}
            </span>
            <ChecButton
              color="orange"
              variant="small"
              @click="editVariant"
            >
              {{ $t('product.variants.editSingle') }}
            </ChecButton>
          </span>
        </ChecAlert>
        <DashboardHeader
          :title="$t('product.variants.variantDetails')"
          margin="small"
          header-tag="h3"
        />
      </div>
      <div class="input-wrapper">
        <ChecImageManager
          v-model="updatedVariant.assets"
          class="images-card__dropzone"
          :normalise-file="normaliseAsset"
          :endpoint="resolveEndpoint"
          :accept-successful-upload="completeUpload"
          :image-gallery="product.assets"
          accepted-files="image/jpeg,image/png,image/gif,image/jpg"
          :footnote="footnote"
        />
      </div>
      <div class="input-wrapper__row space-x-2">
        <div class="input-wrapper">
          <TextField
            v-model="updatedVariant.sku"
            :label="$t('product.sku')"
            name="sku"
          />
        </div>
        <div class="input-wrapper">
          <TextField
            v-model="updatedVariant.inventory"
            :label="$t('general.quantity')"
            name="quantity"
          />
        </div>
      </div>
      <div class="input-wrapper">
        <TextField
          v-model="amount"
          :placeholder="defaultPrice"
          name="price"
          :label="$t('general.price')"
          :currency-symbol="currencySymbol"
          currency
        />
      </div>
      <div class="input-wrapper">
        <TextField
          v-model="updatedVariant.description"
          :label="$t('general.description')"
          name="description"
          multiline
        />
      </div>
    </template>
    <template #toolbar>
      <div class="manage-variants__toolbar space-x-4">
        <ChecButton text-only color="primary" @click="close">
          {{ $t('general.cancel' ) }}
        </ChecButton>
        <ChecButton
          button-type="submit"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >
          {{ saveButtonLabel }}
        </ChecButton>
      </div>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import {
  ChecAlert,
  ChecButton,
  ChecDropdown,
  ChecImageManager,
  ChecLoading,
  ChecSlideoutPanel,
  TextField,
} from '@chec/ui-library';
import { mapGetters } from 'vuex';
import DashboardHeader from '@/components/DashboardHeader.vue';
import addNotification from '@/mixins/addNotification';
import isEqual from 'lodash.isequal';
import upload from '@/mixins/upload';
import crud from '@/mixins/crud';

export default {
  name: 'EditPanel',
  components: {
    ChecAlert,
    ChecButton,
    ChecDropdown,
    ChecImageManager,
    ChecLoading,
    ChecSlideoutPanel,
    DashboardHeader,
    TextField,
  },
  mixins: [
    addNotification,
    upload,
    crud('products/variants', true),
  ],
  props: {
    variant: Object,
    product: Object,
    productPrice: [String, null],
    productVariants: Array,
    currencySymbol: String,
    mode: String,
  },
  data() {
    return {
      saving: false,
      loading: false,
      updatedVariant: this.variant || {},
      selectedOptions: this.product.variantGroups.reduce(
        (acc, group) => ({
          ...acc,
          [group.id]: this.variant && this.variant.options.length
            // Existing variant being edited
            ? this.variant.options.find((option) => option.group.id === group.id)?.id
            // New variant being created
            : '',
        }), {},
      ),
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
    amount: {
      get() {
        const { price } = this.updatedVariant;

        if (price && typeof price === 'object') {
          return price.raw;
        }
        return price;
      },
      set(amount) {
        this.updatedVariant = {
          ...this.updatedVariant,
          price: {
            ...(this.updatedVariant.price || {}),
            raw: amount,
            formatted: amount.toString(),
          },
        };
      },
    },
    /**
     * @returns {string}
     */
    saveButtonLabel() {
      if (this.saving) {
        return this.$t('general.saving');
      }
      if (this.mode === 'add') {
        return this.$t('product.variants.addVariant');
      }
      return this.$t('general.saveChanges');
    },
    /**
     * Calculates a default price for this variant, if there is no specific price set
     *
     * @returns {String}
     */
    defaultPrice() {
      const optionPrice = this.options.length
        ? this.options.reduce((acc, { price }) => acc + (price !== null ? price.raw : 0), 0)
        : 0;

      return (parseFloat(this.productPrice) + optionPrice).toFixed(2);
    },
    /**
     * Return the variant options
     *
     * @returns {Array}
     */
    options() {
      return this.variant.options;
    },
    footnote() {
      return this.$tc('product.images.types', 3, {
        types: 'PNG, JPG',
        lastType: 'GIF',
      });
    },
    /**
     * Create the variant title, if no options exist fallback to default.
     *
     * @returns {string}
     */
    variantTitle() {
      return this.variant.selectedOptions.length
        ? this.$t('product.variants.editSingleNamed', {
          name: this.variant.selectedOptions.map((option) => option.name).join(', '),
        })
        : this.$t('product.variants.editSingle');
    },
    /**
     * Check the product variants to see if the selected combination exists
     * and return the existing variant.
     *
     * @returns {object}
     */
    existingVariant() {
      return this.productVariants.find(({ options: candidateOptions }) => (
        Object.values(this.selectedOptions).every(
          (id) => Object.values(candidateOptions).includes(id),
        )
      ));
    },
    /**
     * Return the current variant for comparison.
     *
     * @returns {object}
     */
    currentVariant() {
      const currentVariant = this.productVariants.find(
        (candidate) => candidate.id === this.updatedVariant.id,
      );

      const {
        description,
        inventory,
        sku,
        price,
        assets,
      } = currentVariant;

      return {
        description,
        inventory,
        sku,
        price,
        assets,
      };
    },
    /**
     * Return the updated variant with only the used fields
     *
     * @returns {object}
     */
    sanitizedUpdatedVariant() {
      return {
        description: this.updatedVariant.description,
        inventory: parseInt(this.updatedVariant.inventory, 10),
        sku: this.updatedVariant.sku,
        price: this.updatedVariant.price,
        assets: this.updatedVariant.assets,
      };
    },
    /**
     * Check that all options dropdowns are selected.
     *
     * @returns {boolean}
     */
    optionsSelected() {
      return !Object.values(this.selectedOptions).every((option) => option !== '');
    },
    /**
     * Check that the variant is allowed to be saved.
     *
     * @returns {boolean}
     */
    allowSave() {
      if (this.mode === 'add') {
        return this.saving || this.existingVariant !== undefined || this.optionsSelected;
      }
      return this.saving || isEqual(this.sanitizedUpdatedVariant, this.currentVariant);
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    sanitizeOptions(options) {
      return options.map((option) => ({
        value: option.id,
        label: option.name,
      }));
    },
    editVariant() {
      this.$emit('open-editor', this.existingVariant.id);
    },
    async save() {
      this.saving = true;
      if (this.saving) {
        this.loading = true;
      }

      if (this.mode === 'add') {
        try {
          await this.create({
            ...this.updatedVariant,
            price: this.updatedVariant.price && typeof this.updatedVariant.price === 'object'
              ? this.updatedVariant.price.raw : this.updatedVariant.price,
            assets: this.updatedVariant.assets.map(({ id }) => id),
            options: this.selectedOptions,
          });
          this.addNotification(this.$t('product.variants.singleCreateSuccess'));
          this.$emit('close');
        } catch (error) {
          this.addNotification(this.$t('product.variants.singleUpdateFailure'), 'error');
        }
        this.saving = false;
        return;
      }

      try {
        await this.update(this.updatedVariant.id, {
          ...this.updatedVariant,
          price: this.updatedVariant.price && typeof this.updatedVariant.price === 'object'
            ? this.updatedVariant.price.raw : this.updatedVariant.price,
          assets: this.updatedVariant.assets.map(({ id }) => id),
          options: this.selectedOptions,
        }, false, false);

        this.addNotification(this.$t('product.variants.singleUpdateSuccess'));
        this.$emit('close');
      } catch (error) {
        this.addNotification(this.$t('product.variants.singleUpdateFailure'), 'error');
      } finally {
        this.loading = false;
      }
      this.saving = false;
    },
  },
};
</script>

<style lang="scss">
.manage-variants {
  &__toolbar,
  &__hero-toolbar {
    @apply w-full flex justify-end items-center;
  }

  &__hero-toolbar {
    @apply mt-8;
  }
}

.images-card {
  &__dropzone {
    @apply mt-4;
  }
}

.variants-edit {
  &__invalid-alert {
    @apply mb-4;
  }

  &__alert {
    @apply mt-2;
  }

  &__alert-copy {
    @apply flex justify-between items-center w-full;
  }

  &__options-listing {
    @apply flex flex-wrap mb-4;
  }

  &__option {
    flex: 1 1 20%;
  }
}
</style>
