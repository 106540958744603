<template>
  <div v-if="attribute" class="attribute-field ">
    <!-- type: short_text, long_text, number -->
    <TextField
      v-if="[
        'short_text',
        'long_text',
        'number',
      ].includes(attribute.type)"
      :multiline="attribute.type === 'long_text'"
      :name="attribute.name"
      :required="attribute.required === true"
      :variant="errors ? 'error' : ''"
      :type="[
        'short_text',
        'long_text',
      ].includes(attribute.type)
        ? 'text' : attribute.type"
      :label="attribute.name"
      :value="value"
      @input="value => $emit('input', value)"
    />

    <!-- type: options -->
    <ChecDropdown
      v-if="attribute.type === 'options' && hasOptions"
      :value="attribute.is_multiselect && value === null ? '' : value"
      :options="attribute.options"
      :label="attribute.name"
      :multiselect="attribute.is_multiselect"
      :name="attribute.name"
      :required="attribute.required === true"
      :variant="errors ? 'error' : ''"
      @input="value => $emit('input', value)"
    />

    <!-- type: date -->
    <ChecDatepicker
      v-if="attribute.type === 'date'"
      :label="attribute.name"
      :value="value"
      :required="attribute.required === true"
      :variant="errors ? 'error' : ''"
      @input="value => $emit('input', value)"
    />

    <!-- type: radio -->
    <div v-if="attribute.type === 'radio'">
      <h4 class="attribute-field__radio-label">
        {{ attribute.name }}
      </h4>
      <ChecRadioGroup
        :options="attribute.options"
        :label="attribute.name"
        :name="attribute.name"
        :value="value || ''"
        :required="attribute.required === true"
        class="attribute-field__radio"
        @input="value => $emit('input', value)"
      />
    </div>

    <!-- type: checkbox -->
    <div v-if="attribute.type === 'checkbox'">
      <ChecCheckbox
        :label="attribute.name"
        :name="attribute.name"
        :required="attribute.required === true"
        :checked="value"
        @input="value => $emit('input', value)"
      >
        {{ attribute.name }}
      </ChecCheckbox>
    </div>

    <!-- Add required in the label if attribute is required -->
    <span v-if="errors" class="input-wrapper__error">
      {{ errors.value }}
    </span>
  </div>
</template>

<script>
import {
  ChecCheckbox,
  ChecDatepicker,
  ChecDropdown,
  ChecRadioGroup,
  TextField,
} from '@chec/ui-library';

export default {
  name: 'AttributesField',
  components: {
    ChecCheckbox,
    ChecDatepicker,
    ChecDropdown,
    ChecRadioGroup,
    TextField,
  },
  props: {
    attribute: Object,
    value: [Array, String, Number, Boolean],
    errors: Object,
  },
  computed: {
    /**
     * Determines whether the attribute has options
     *
     * @returns {Boolean}
     */
    hasOptions() {
      return this.attribute?.options?.length > 0;
    },
  },
};
</script>

<style lang="scss">
.attribute-field {
  &__radio-label {
    @apply caps-xs pb-2;
  }

  &__radio {
    &:not(:last-child) {
      @apply pb-2;
    }
  }
}
</style>
