<template>
  <v-popover
    trigger="hover"
    placement="top"
    :popover-base-class="['price-tooltip tooltip', {
      'price-tooltip--has-set-price': setPrice !== null,
    }]"
    popover-inner-class="price-tooltip__content tooltip-inner"
  >
    <slot />
    <template #popover>
      <div class="price-tooltip__row">
        <span>{{ $t('product.basePrice') }}</span>
        <span>{{ formattedProductPrice }}</span>
      </div>
      <div
        v-for="{ id, name, price } in options"
        :key="id"
        class="price-tooltip__row"
      >
        <span>{{ name }}</span>
        <span v-if="price">{{ formattedVariantPrice(price) }}</span>
        <span v-else>&mdash;</span>
      </div>
      <div class="price-tooltip__row">
        <span>{{ $t('general.total') }}</span>
        <span>{{ total }}</span>
      </div>
      <div v-if="formattedSetPrice" class="price-tooltip__row">
        <span>{{ $t('product.variants.override') }}</span>
        <span>{{ formattedSetPrice }}</span>
      </div>
    </template>
  </v-popover>
</template>

<script>
import { formatCurrency } from '@/lib/locale';

export default {
  name: 'VariantPriceTooltip',
  props: {
    currency: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    productPrice: {
      type: String,
      required: true,
    },
    setPrice: {
      type: Number,
      default: null,
    },
  },
  computed: {
    /**
     * Calculates the total price of the variant from the options and the product price
     *
     * @returns {String}
     */
    total() {
      return formatCurrency(
        parseFloat(this.productPrice) + this.options.reduce(
          (acc, { price }) => acc + (price?.raw || 0),
          0,
        ),
        this.currency,
      );
    },
    formattedProductPrice() {
      return formatCurrency(parseFloat(this.productPrice), this.currency);
    },
    formattedSetPrice() {
      if (this.setPrice === null) {
        return null;
      }
      return formatCurrency(this.setPrice, this.currency);
    },
  },
  methods: {
    formattedVariantPrice(price) {
      return formatCurrency(parseFloat(price.formatted), this.currency);
    },
  },
};
</script>

<style lang="scss">
.price-tooltip {
  // Too much specificity in the base styles :(
  &.tooltip .tooltip-inner {
    @apply text-white;
  }

  &--has-set-price {
    &.tooltip .tooltip-inner {
      @apply text-gray-300 text-opacity-75;

      .price-tooltip__row:last-child {
        @apply text-white;
      }
    }
  }

  &__content {
    // Work around the DOM generated by the vendor popover lib, that insert an additional wrapper
    // div without a class
    > * {
      @apply -my-2;
    }
  }

  &__row {
    @apply flex justify-between py-2 leading-normal font-normal;

    & + & {
      @apply border-t border-gray-500;
    }

    :first-child {
      @apply mr-6;
    }

    &:last-child {
      @apply border-gray-200 font-bold;
    }
  }
}
</style>
