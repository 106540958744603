<template>
  <div class="extra-fields__row">
    <div class="extra-fields__row-wrapper">
      <div class="extra-fields__row-input-container">
        <TextField
          v-model="name"
          name="name"
          class="extra-fields__row-input"
          type="text"
          :variant="errors.name ? 'error' : ''"
          required
          :label="$t('extraFields.name')"
          @input="(value) => handleChange('name', value)"
        >
          <ChecSwitch
            v-model="isRequired"
            prefix-label
            @input="(value) => handleChange('required', value)"
          >
            {{ $t('general.required') }}
          </ChecSwitch>
        </TextField>
      </div>
      <ChecButton
        color="secondary"
        variant="small"
        class="extra-fields__row-btn"
        :title="$t('general.delete')"
        @click="handleDelete"
      >
        <template #icon>
          <ChecIcon icon="delete" />
        </template>
      </ChecButton>
    </div>
    <span v-if="errors.name" class="input-wrapper__error">{{ errors.name }}</span>
  </div>
</template>

<script>
import {
  TextField,
  ChecSwitch,
  ChecButton,
  ChecIcon,
} from '@chec/ui-library';

export default {
  name: 'Row',
  components: {
    TextField,
    ChecSwitch,
    ChecButton,
    ChecIcon,
  },
  props: {
    index: Number,
    row: Object,
    errors: Object,
  },
  data() {
    return {
      isRequired: false,
      name: '',
    };
  },
  mounted() {
    if (this.row.required) {
      this.isRequired = true;
    }
    if (this.row.name) {
      this.name = this.row.name;
    }
  },
  methods: {
    handleChange(prop, value) {
      this.$emit('change', {
        ...this.row,
        [prop]: value,
      });
    },
    handleDelete() {
      this.$emit('delete', this.index);
    },
  },
};
</script>
