<template>
  <ChecCard
    class="product-detail__card"
    inner-class="details-card"
  >
    <ChecHeader
      :title="$t('product.seo')"
      variant="card"
      header-tag="h3"
    />
    <div class="input-wrapper">
      <TextField
        :value="product.permalink"
        name="permalink"
        :variant="errors.permalink ? 'error' : ''"
        :label="$t('product.customPermalink')"
        @input="value => handleChangePermalink(value)"
      />
      <span v-if="errors.permalink" class="input-wrapper__error">
        {{ errors.permalink }}
      </span>
    </div>
    <div class="input-wrapper">
      <TextField
        :value="product.seo.title"
        name="seotitle"
        :label="$t('product.searchEngineTitle')"
        @input="value => handleChangeSeo('title', value)"
      />
    </div>
    <div class="input-wrapper">
      <TextField
        :value="product.seo.description"
        name="seodescription"
        :label="$t('product.searchEngineDescription')"
        multiline
        @input="value => handleChangeSeo('description', value)"
      />
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecHeader,
  TextField,
} from '@chec/ui-library';
import slugify from 'slugify';

export default {
  name: 'SeoCard',
  components: {
    ChecCard,
    ChecHeader,
    TextField,
  },
  model: {
    prop: 'product',
    event: 'input',
  },
  props: {
    errors: {
      type: Object,
      default: () => ({}),
    },
    product: {
      type: Object,
    },
  },
  methods: {
    change(prop, value) {
      this.$emit('input', {
        ...this.product,
        [prop]: value,
      });
    },
    /**
     * Change handler for the "permalink" attribute. Removes spaces,
     * non-alphanumeric chars, converts to lowercase etc.
     */
    handleChangePermalink(value) {
      // Replace spaces with dashes, then "slugify" it
      const permalink = slugify(value.replace(/\s/g, '-'), {
        lower: true,
        strict: true,
        // todo support locales here: https://www.npmjs.com/package/slugify#options
      });
      this.change('permalink', permalink);
    },
    /**
     * Change handler for the "options" switches
     */
    handleChangeSeo(option, value) {
      this.change('seo', {
        ...this.product.seo,
        [option]: value,
      });
    },
  },
};
</script>

<style lang="scss">
.details-card {
  @apply p-4;

  .chec-header {
    @apply mb-4; }
}
</style>
