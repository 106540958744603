<template>
  <ChecCard
    class="product-detail__card"
    inner-class="categories-card"
  >
    <ChecLoading v-if="loading" />
    <ChecHeader
      :title="$t('category.categories')"
      variant="card"
    />
    <ResourceDropdown
      v-if="!loading && (totalItemCount > 0 || hasSearch)"
      v-model="categoriesData"
      name="product.categories"
      state-key="categories"
      :placeholder="$t('category.selectPlaceholder')"
      multiselect
    />
    <router-link
      v-else
      :to="{ name: 'categories.home' }"
    >
      {{ $t('category.editMultiple') }}
    </router-link>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecHeader,
  ChecLoading,
} from '@chec/ui-library';
import ResourceDropdown from '@/components/ResourceDropdown.vue';
import crud from '@/mixins/crud';

export default {
  name: 'CategoryCard',
  components: {
    ChecCard,
    ChecHeader,
    ChecLoading,
    ResourceDropdown,
  },
  mixins: [crud('categories')],
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // Triggered when the component first mounts, and used to decide whether
      // to render the ResourceDropdown
      loading: true,
    };
  },
  computed: {
    categoriesData: {
      /**
       * Normalise value from an object into a string ID
       *
       * @returns {string[]}
       */
      get() {
        return this.value.map(({ id }) => id);
      },
      /**
       * Convert string ID back into an object
       *
       * @param {string} value
       */
      set(value) {
        this.$emit('change', value.map((id) => ({ id })));
      },
    },
    hasSearch() {
      return this.isSearching || this.currentSearchTerm.length > 0;
    },
  },
  mounted() {
    // Prefetch categories. We need to do this because the ResourceDropdown won't
    // fetch anything if we don't render it.
    this.loadPage(1, true).then(() => {
      this.loading = false;
    });
  },
};
</script>

<style lang="scss">
.categories-card {
  @apply p-4;

  .chec-header {
    @apply mb-4;
  }
}
</style>
