<template>
  <ChecAccordion
    :title="zone.name"
    :subtitle="subtitle"
    variant="switch"
    class="shipping-card__item"
    :open="shippingOptions.length !== 0"
    @toggled="handleToggleZone"
  >
    <ChecLoading v-if="isLoading" />
    <div v-if="rates.length">
      <Row
        v-for="(rate, index) in rates"
        :key="`${index}${rate.id}`"
        :rate="rate"
        :validation-errors="validationErrors"
        :product-rates="productRates"
        :shipping-option="getOption(rate)"
        :currency-symbol="currencySymbol"
        @change="(row) => handleChangeRow(index, row)"
      />
    </div>
  </ChecAccordion>
</template>

<script>
import {
  ChecAccordion,
  ChecLoading,
} from '@chec/ui-library';
import { mapActions, mapGetters } from 'vuex';
import actions from '@/lib/pagination/actions';
import getters from '@/lib/pagination/getters';
import Row from './Row.vue';

export default {
  name: 'Zone',
  components: {
    ChecAccordion,
    ChecLoading,
    Row,
  },
  /**
   * This component takes all shipping options applicable for the given zone as a model. These are
   * provided by the API as individual objects for each rate and zone combination. The change event
   * will emit all shipping options for the zone.
   */
  model: {
    prop: 'shippingOptions',
    event: 'change',
  },
  props: {
    zone: Object,
    productRates: Array,
    shippingOptions: Array,
    validationErrors: Object,
    currencySymbol: String,
  },
  data() {
    return {
      isLoading: true,
      rates: [],
      optionSchema: {
        zone_id: null,
        rate: {
          id: null,
        },
        with_others: {
          raw: 0,
        },
        on_own: {
          raw: 0,
        },
      },
    };
  },
  computed: {
    ...mapGetters('fulfillment/rates', { getRates: getters.GET_PAGE }),
    subtitle() {
      const { countries } = this.zone;
      if (countries.length <= 3) {
        return countries.join(', ');
      }

      return this.$tc('product.includesNCountries', countries.length, { number: countries.length });
    },
  },
  mounted() {
    if (this.shippingOptions.length) {
      this.loadRates();
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    // Map the CRUD helper to load all shipping rates (per zone)
    ...mapActions('fulfillment/rates', { loadRateData: actions.LOAD_REMAINING_ITEMS }),
    /**
     * Handle the change on an individual row, and emit the change event
     *
     * @param {Number} rowIndex
     * @param {Object} shippingOption
     */
    handleChangeRow(rowIndex, shippingOption) {
      const options = [...this.shippingOptions];
      options.splice(rowIndex, 1, shippingOption);
      this.$emit('change', options);
    },
    /**
     * Find the shipping option for a given rate, or create a blank one if there is none
     *
     * @param {{ id: String }} rate
     */
    getOption(rate) {
      return this.shippingOptions.find(
        (shippingOption) => shippingOption.rate.id === rate.id,
      ) || {
        ...this.optionSchema,
        zone_id: this.zone.id,
        rate: {
          id: rate.id,
        },
      };
    },
    /**
     * Handler for when a zone is enabled or disabled
     *
     * @param {boolean} isEnabled
     */
    async handleToggleZone(isEnabled) {
      await this.loadRates();

      if (isEnabled) {
        // When the zone is enabled, we need to emit a change that makes sure that a shipping option
        // exist for each rate
        this.$emit('change', this.rates.map(this.getOption));
      } else {
        // Otherwise, we want to emit that this zone should not have _any_ shipping options
        this.$emit('change', []);
      }
    },
    async loadRates() {
      if (this.rates.length !== 0) {
        return;
      }
      this.isLoading = true;
      await this.loadRateData({ zone: this.zone.id });
      this.rates = this.getRates(1, { zone: this.zone.id });
      this.isLoading = false;
    },
  },
};
</script>
